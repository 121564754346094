export default {
  getFichaTecnica(state) {
    return state.fichaTecnica;
  },

  getTabelaVendas(state) {
    return state.tabelaVendas;
  },

  getMonitoramento(state) {
    return state.monitoramento;
  },
};
