//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import NavItem from './NavItem.vue';
import NavHR from './NavHR.vue';

export default Vue.extend({
  name: 'NavBody',

  components: {
    'nav-item': NavItem,
    'nav-hr': NavHR,
  },

  props: ['user-destination', 'isMobile'],

  data() {
    return {
      nav: this.$store.state.Menu.menuItems,
      externalStatusList: [],
      shouldHaveGhost: false,
    };
  },

  mounted() {
    //set visible and enabled for each menu item based on user profiles
    this.$store.commit('Menu/setVisibleAndEnabled', this.$store.state.Session.profiles);

    this.forceActiveBar(this.userDestination);

    let element = document.getElementById('scrollerContainer');
    let viewHeight = Math.max(element.clientHeight, window.innerHeight);
    // each element has 80px height
    let elementTruHeight = (this.topItems.length + this.middleItems.length + this.bottomItems.length) * 80;

    window.addEventListener('resize', () => {
      this.checkScreenSize(element, elementTruHeight);
    });

    // menu view port is only 80% of the screen beacuse of the header and footer
    if (viewHeight * 0.8 > elementTruHeight) {
      this.$store.commit('Menu/setBiggerThanScreen', false);
    } else {
      this.$store.commit('Menu/setBiggerThanScreen', true);
    }
  },

  destroyed() {
    window.removeEventListener('resize', () => {
      this.checkScreenSize();
    });
  },

  computed: {
    topItems() {
      return this.nav.filter((item) => item.position === 'top' && !item.hidden && (this.isMobile ? item.mobile : true));
    },

    middleItems() {
      return this.nav.filter(
        (item) => item.position === 'middle' && !item.hidden && (this.isMobile ? item.mobile : true)
      );
    },

    bottomItems() {
      return this.nav.filter(
        (item) => item.position === 'bottom' && !item.hidden && (this.isMobile ? item.mobile : true)
      );
    },

    checkOpenExternal() {
      return this.nav.some((item) => item.open === true);
    },
  },

  methods: {
    forceActiveBar(path) {
      this.nav.forEach((item) => {
        if (item.path === path) {
          this.$store.commit('Menu/setActive', item);
        }

        if (item.subMenus) {
          item.subMenus.forEach((subItem) => {
            if (subItem.path === path) {
              this.$store.commit('Menu/setActive', subItem);
            }

            if (subItem.subMenus) {
              subItem.subMenus.forEach((subSubItem) => {
                if (subSubItem.path === path) {
                  this.$store.commit('Menu/setActive', subSubItem);
                }
              });
            }
          });
        }
      });
    },

    logout(oldActiveId) {
      let oldActiveItem = null;
      this.nav.forEach((item) => {
        if (item.id === oldActiveId) {
          oldActiveItem = item;
        }

        if (item.subMenus && item.subMenus.length > 0) {
          item.subMenus.forEach((subItem) => {
            if (subItem.id === oldActiveId) {
              oldActiveItem = subItem;
            }

            if (subItem.subMenus && subItem.subMenus.length > 0) {
              subItem.subMenus.forEach((subSubItem) => {
                if (subSubItem.id === oldActiveId) {
                  oldActiveItem = subSubItem;
                }
              });
            }
          });
        }
      });

      this.$emit('logout', oldActiveItem);
    },

    checkScreenSize(element, elementTruHeight) {
      let viewHeight = Math.max(element.clientHeight, window.innerHeight);

      if (viewHeight * 0.8 > elementTruHeight) {
        this.$store.commit('Menu/setBiggerThanScreen', false);
      } else {
        this.$store.commit('Menu/setBiggerThanScreen', true);
      }
    },
  },

  watch: {
    userDestination: {
      handler(val) {
        this.forceActiveBar(val);
      },
      deep: true,
    },
  },
});
