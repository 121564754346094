//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import NavHR from '../NavHR.vue';

export default Vue.extend({
  name: 'ExternalTitle',

  props: ['item'],

  components: {
    'nav-hr': NavHR,
  },
});
