export const CLEAR_STATE = {
  id_property_type: [],
  id_project_phase: [],
  state: [],
  region: [],
  city: [],
  district: [],
  neighborhood: [],
  project_property: [],
  id_builder: [],
  id_developer: [],
  developerObj: [],
  id_real_estate: [],
  ids_banks: [],
  id_value_zone: [],
  bedroom: [],
  garage: [],
  inicio_lancamento: '',
  fim_lancamento: '',
  area_privativa: [0, 4000],
  valor: [0, 100000000],
  vendido: [0, 100],
  valor_m2: [0, 130000],
  locationBlock: false,
  propertyBlock: false,
  isPolygonDrawn: false,
  isFilterApplied: false,
  autoCompleteOriginalSearch: '',
  filterValues: new Object(),
};

export const state = () => ({
  showFiltersFlag: false,
  fieldValue: '',
  fieldId: 0,
  autocompleteTypeSelected: '',
  showAutocompleteContainer: false,
  showMoreFiltersFlag: false,
  filterValues: {
    id_property_type: [],
    id_project_phase: [],
    state: [],
    region: [],
    city: [],
    district: [],
    neighborhood: [],
    project_property: [],
    id_builder: [],
    id_developer: [],
    developerObj: [],
    id_real_estate: [],
    ids_banks: [],
    id_value_zone: [],
    bedroom: [],
    garage: [],
    inicio_lancamento: '',
    fim_lancamento: '',
    area_privativa: [0, 4000],
    valor: [0, 100000000],
    vendido: [0, 100],
    valor_m2: [0, 130000],
  },
  locationBlock: false,
  propertyBlock: false,
  isPolygonDrawn: false,
  isFilterApplied: false,
  autoCompleteOriginalSearch: '',
});
