//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import ExternalTitle from './ExternalTitle.vue';
import ExternalItem from './ExternalItem.vue';

export default Vue.extend({
  name: 'ExternalSubBody',

  components: {
    'external-title': ExternalTitle,
    'external-item': ExternalItem,
  },

  props: ['item', 'tirthLevel', 'order', 'length'],

  computed: {
    validSubMenu() {
      return this.item.subMenus.filter((menu) => !menu.hidden);
    },
  },

  data() {
    return {
      altOrder: Math.floor(Math.random() * (10 - 1 + 1) + 1),
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getStyle();
    });
  },

  methods: {
    closeSubmenu() {
      this.$emit('closeSubmenu');
    },

    async getStyle() {
      let element = document.getElementById(
        `external-body-${!this.order && this.order !== 0 ? this.altOrder : this.order}`
      );
      let rect = element.getBoundingClientRect();
      let viewHeight = Math.max(element.clientHeight, window.innerHeight);

      let left = '80px';
      let top = 0;
      let bottom = 'unset';

      if (!this.tirthLevel && !this.$store.state.Menu.firstLevel) {
        left = '80px';
      } else if (!this.tirthLevel && this.$store.state.Menu.firstLevel) {
        left = '313px';
      } else if (this.tirthLevel && !this.$store.state.Menu.firstLevel) {
        left = '362px';
      } else if (this.tirthLevel && this.$store.state.Menu.firstLevel) {
        left = '362px';
      }

      if (this.tirthLevel) {
        //rect is giving 85 less pixels to the element, I dont know why
        if (rect.bottom + 85 - viewHeight >= 0) {
          //out of screen
          bottom = `${(this.length - this.order) * 80}px`;
          top = 'unset !important';
        } else {
          // in the screen
          bottom = 'unset';
          top = `${41 + (this.order - 1) * 80}px`;
        }
      }

      element.style.bottom = bottom;
      element.style.left = left;
      element.style.top = top;
      element.style.width = this.tirthLevel ? '362px' : '230px';
    },
  },
});
