//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  name: 'ExternalItem',

  props: ['item', 'first', 'last'],

  methods: {
    selectItem() {
      if (this.item && this.item.enabled) {
        if (this.item.path) {
          const navItem = document.getElementById(`nav-last-${this.item.id}`);
          navItem.click();
          this.$store.commit('Menu/setActive', this.item);
          this.$store.commit('Menu/setItemClose', this.item);
          this.$emit('closeSubmenu');
        }
      }
    },
  },
});
