export default class GoogleAnalytics {
  constructor($store) {
    this.$store = $store;
  }

  pageView({ pageName, pageType, filterNavigation = false, setPreviousPageName = true }) {
    const previousPageName = this.$store.state.GoogleAnalytics.previousPageName;

    window.dataLayer = window.dataLayer || [];

    const data = {
      event: 'customPageView',
      siteInfo: {
        hostname: document.location.hostname,
        pathname: document.location.pathname,
        protocol: document.location.protocol,
        search: document.location.search,
        hash: document.location.hash,
        href: document.location.href,
        referrer: document.referrer,
        division: 'datazap',
      },
      pageInfo: {
        costumerType: 'b2b',
        brand: 'datazap',
        brandId: '5',
        segment: 'real-estate',
        pageName,
        previousPageName,
        pageType,
        flowType: 'datazap',
        ...(filterNavigation ? { filterNavigation: this.getFilterNavigation() } : null),
      },
      userInfo: this.getUserInfo(),
    };

    window.dataLayer.push(data);

    if (setPreviousPageName) {
      this.$store.commit('GoogleAnalytics/setPreviousPageName', pageName);
    }
  }

  event({ category, action, label }) {
    window.dataLayer = window.dataLayer || [];

    const data = {
      event: 'datazap_engagement_event',
      eventInfo: {
        category,
        action,
        label,
      },
    };

    window.dataLayer.push(data);
  }

  getUserInfo() {
    return {
      userInfo: {
        user_id: this.$store.state.Session.userID || undefined,
        loginStatus: this.$store.state.Session.userID ? true : false,
        loginType: this.$store.state.Session.userID ? 'email' : undefined,
      },
    };
  }

  getFilterNavigation() {
    return this.$store.state.GoogleAnalytics.filter;
  }

  setFilterNavigation(value) {
    this.$store.commit('GoogleAnalytics/setFilter', value);
  }

  getSearchType() {
    return this.$store.state.GoogleAnalytics.searchType;
  }

  setSearchType(value) {
    this.$store.commit('GoogleAnalytics/setSearchType', value);
  }
}
