export default class Map {
  constructor() {
    this.map = null;
  }

  getMap() {
    return this.map;
  }

  setMap(map) {
    this.map = map;
  }
}
