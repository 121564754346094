export const state = () => ({
  menuItems: [
    {
      id: 0,
      label: 'Overview',
      icon: './img/menuOverview.svg',
      active: false,
      mobile: false,
      enabled: false,
      hidden: false,
      isNew: false,
      path: '/overview',
      position: 'top',
      level: 1,
    },
    {
      id: 1,
      label: 'Geoimovel',
      icon: './img/menuGeo.svg',
      active: true,
      mobile: false,
      enabled: false,
      hidden: false,
      isNew: false,
      path: '/geoimovel',
      position: 'top',
      level: 1,
    },
    {
      id: 2,
      label: 'Precificação',
      icon: './img/menuPrecificacao.svg',
      active: false,
      mobile: true,
      enabled: false,
      hidden: false,
      isNew: false,
      position: 'top',
      level: 1,
      open: false,
      subMenus: [
        {
          id: 3,
          label: 'Quanto Vale?',
          active: false,
          mobile: true,
          path: '/QuantoVale/quantovale',
          enabled: false,
          hidden: false,
          parent: 2,
          level: 2,
        },
        {
          id: 4,
          label: 'Quanto Vale Pro',
          active: false,
          mobile: true,
          path: '/QuantoValePro',
          enabled: false,
          hidden: false,
          parent: 2,
          level: 2,
        },
        {
          id: 5,
          label: 'Parecer Técnico',
          active: false,
          path: '/ParecerTecnico/parecerTecnico',
          mobile: true,
          enabled: false,
          hidden: false,
          parent: 2,
          level: 2,
        },
      ],
    },
    {
      id: 6,
      label: 'Estudos',
      icon: './img/menuEstudos.svg',
      active: false,
      mobile: false,
      enabled: false,
      hidden: false,
      isNew: false,
      position: 'top',
      level: 1,
      open: false,
      subMenus: [
        {
          id: 7,
          label: 'Estudo de Mercado',
          active: false,
          mobile: false,
          path: '/Estudos/estudoDeMercado',
          enabled: false,
          hidden: false,
          parent: 6,
          level: 2,
        },
        {
          id: 8,
          label: 'Pesquisa Qualitativa',
          active: false,
          mobile: false,
          path: '/Estudos/pesquisaQualitativa',
          enabled: false,
          hidden: false,
          parent: 6,
          level: 2,
        },
        {
          id: 9,
          label: 'Perfil do Lead',
          active: false,
          mobile: false,
          path: '/Estudos/perfilDoLead',
          enabled: false,
          hidden: false,
          parent: 6,
          level: 2,
        },
        {
          id: 10,
          label: 'Onde Vale',
          active: false,
          mobile: false,
          path: '/Estudos/ondeVale',
          enabled: false,
          hidden: false,
          parent: 6,
          level: 2,
        },
        {
          id: 11,
          label: 'Lupa',
          active: false,
          mobile: false,
          path: '/Estudos/lupa',
          enabled: false,
          hidden: false,
          parent: 6,
          level: 2,
        },
      ],
    },
    {
      id: 12,
      label: 'Ajuda',
      icon: './img/icon-support.svg',
      active: false,
      path: '/suporte',
      mobile: true,
      enabled: true,
      hidden: false,
      isNew: false,
      position: 'middle',
      level: 1,
    },
    {
      id: 13,
      label: 'Mais',
      icon: './img/more.svg',
      active: false,
      mobile: false,
      enabled: true,
      hidden: false,
      isNew: false,
      position: 'middle',
      level: 1,
      open: false,
      subMenus: [
        {
          id: 14,
          label: 'Consultoria',
          addInfo: 'Cadastre e consulte suas pesquisas',
          icon: './img/icon-consultancy.svg',
          active: false,
          mobile: false,
          enabled: false,
          hidden: true,
          parent: 13,
          level: 2,
          subMenus: [
            {
              id: 15,
              label: 'Novo Estudo',
              active: false,
              mobile: false,
              path: '/incluirestudo',
              enabled: false,
              hidden: true,
              parent: 14,
              level: 3,
            },
            {
              id: 16,
              label: 'Consulta de Estudos',
              active: false,
              mobile: false,
              path: '/consultaestudo',
              enabled: false,
              hidden: true,
              parent: 14,
              level: 3,
            },
            {
              id: 17,
              label: 'Pesquisa',
              active: false,
              mobile: false,
              path: '/Consultoria/pesquisa/filtros',
              enabled: false,
              hidden: true,
              parent: 14,
              level: 3,
            },
            {
              id: 18,
              label: 'Cadastro Imóvel',
              active: false,
              mobile: false,
              path: '/cadastroimovel',
              enabled: false,
              hidden: true,
              parent: 14,
              level: 3,
            },
            {
              id: 19,
              label: 'Cadastro de Usuários',
              active: false,
              mobile: false,
              path: '/cadastrocliente',
              enabled: false,
              hidden: true,
              parent: 14,
              level: 3,
            },
            {
              id: 20,
              label: 'Cadastro da Empresa',
              active: false,
              mobile: false,
              path: '/cadastroempresacliente',
              enabled: false,
              hidden: true,
              parent: 14,
              level: 3,
            },
            {
              id: 21,
              label: 'IMC Entregas (Onde Vale)',
              active: false,
              mobile: false,
              path: '/Consultoria/ondeVale',
              enabled: false,
              hidden: true,
              parent: 14,
              level: 3,
            },
          ],
        },
        {
          id: 22,
          label: 'Ferramentas',
          addInfo: 'Explore seus recursos diponíveis',
          icon: './img/icon-tools.svg',
          active: false,
          mobile: false,
          enabled: false,
          hidden: true,
          isNew: false,
          parent: 13,
          level: 2,
          open: false,
          subMenus: [
            {
              id: 23,
              label: 'Tipologia GeoCEP',
              active: false,
              mobile: false,
              path: '/Ferramentas/geoCep',
              enabled: false,
              hidden: true,
              parent: 22,
              level: 3,
            },
            {
              id: 24,
              label: 'EC2',
              active: false,
              mobile: false,
              path: '/Ferramentas/ec2',
              enabled: false,
              hidden: true,
              parent: 22,
              level: 3,
            },
            {
              id: 25,
              label: 'Upload',
              active: false,
              mobile: false,
              path: '/Ferramentas/upload',
              enabled: false,
              hidden: true,
              parent: 22,
              level: 3,
            },
            {
              id: 26,
              label: 'IPCA',
              active: false,
              mobile: false,
              path: '/Ferramentas/ipca',
              enabled: false,
              hidden: true,
              parent: 22,
              level: 3,
            },
          ],
        },
        {
          id: 27,
          label: 'Configurações',
          addInfo: 'Acesse seus arquivos e altere seus dados',
          icon: './img/icon-settings.svg',
          active: false,
          mobile: false,
          path: '/configuracao',
          enabled: true,
          hidden: false,
          parent: 13,
          level: 2,
        },
      ],
      parent: 13,
    },
    {
      id: 28,
      label: 'Sair',
      icon: './img/icon-log-out.svg',
      active: false,
      mobile: true,
      path: '/logout',
      enabled: true,
      hidden: false,
      isNew: false,
      position: 'bottom',
      level: 1,
    },
  ],
  firstLevel: false,
  currentActive: 999,
  biggerThanScreen: false,
});
