var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:(this.$store.state.Session.expired && !this.$store.state.Session.userLogoff) ||
    (this.$store.state.Session.oldGeo && !this.$store.state.Session.expired) ||
    this.$store.state.Session.userLogoff
      ? 'platform-login__container--logoff'
      : 'platform-login__container'},[(
      (this.$store.state.Session.expired && !this.$store.state.Session.userLogoff) ||
      (this.$store.state.Session.oldGeo && !this.$store.state.Session.expired) ||
      this.$store.state.Session.userLogoff
    )?_c('img',{staticClass:"platform-login__logo--logoff-img",attrs:{"src":require("~/assets/img/logo.png")}}):_vm._e(),_vm._v(" "),(this.$store.state.Session.expired)?_c('div',{staticClass:"temporary--mesage"},[(this.$store.state.Session.expired && !this.$store.state.Session.userLogoff)?_c('c-dialog',{attrs:{"title":"Sua sessão expirou","text":"Realize o login novamente","custom-labels":['Entendi'],"theme":"secondary","type":"info","right-buttons":true,"justify-text":true},on:{"close":_vm.backToLogin}}):_vm._e(),_vm._v("\n    Obrigado por utilizar nossos serviços.\n  ")],1):_vm._e(),_vm._v(" "),(this.$store.state.Session.oldGeo && !this.$store.state.Session.expired)?_c('div',{staticClass:"temporary--mesage"},[(_vm.$store.state.Session.profileError)?_c('c-dialog',{attrs:{"title":"Problemas ao obter perfis","text":"Ocorreu problema ao obter os perfis do usuário ou ele não possui perfil válido.","theme":"secondary","type":"info","right-buttons":true,"justify-text":true},on:{"close":_vm.backToLogin}}):_vm._e(),_vm._v("\n    Por favor aguarde a validação do Login.\n  ")],1):_vm._e(),_vm._v(" "),(!this.$store.state.Session.oldGeo && !this.$store.state.Session.userLogoff)?_c('div',[_c('div',{staticClass:"platform-login__fields-logo--container"},[_c('nuxt')],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }