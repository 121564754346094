//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import NavHR from './NavHR.vue';

export default Vue.extend({
  name: 'NavFooter',

  components: {
    'nav-hr': NavHR,
  },

  props: {
    userFullName: String,
  },
});
