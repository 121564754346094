import CensoItem from './CensoItem.type';
import Communication from './Communication';
import Map from './Map';
import ProjectPropertiesStore from './ProjectPropertiesStore';

const LIMITED_CENSO_DATA = 10;
const LIMITED_CENSO_DATA_HAS_SHAPES = 30000;

export type CensoData = {
  circles: Circle[];
  polygons: Polygon[];
};

export type Circle = {
  lat: number;
  lng: number;
  radius: number;
  parentId: string;
};

export type Polygon = {
  data: [number, number][];
  shapeId?: string; // undefined for states, cities & neighborhoods
};

type GeoCensusSector = {
  type: string;
  coordinates: [number, number][][][];
};

type ResponseJson = {
  records: CensoItem[];
};

type DataType = 'layers' | 'report' | 'charts';

function calculateAverage(numberArray: number[]) {
  if (numberArray.length === 0) {
    return 0;
  }

  const sum = numberArray.reduce((acc, number) => acc + number, 0);
  const average = sum / numberArray.length;

  return average;
}

export default class Censo {
  private censoQuery: CensoData;
  private polygons: any[] = [];
  private total = [];
  private isLimited = false;

  constructor(
    private $store: Record<string, any>,
    private map: Map,
    private projectPropertiesStore: ProjectPropertiesStore
  ) {
    this.censoQuery = this.$store.state.CensoQuery;
    this.total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  }

  public async getData(type: DataType): Promise<{
    data?: CensoItem[];
    isLimited?: boolean;
    error?: string;
  }> {
    let data: CensoItem[] = [];
    let hasShapes = false;
    this.isLimited = false;

    try {
      if (this.censoQuery.circles.length) {
        hasShapes = true;
        data = data.concat(await this.getCircles(type));
      }

      if (this.censoQuery.polygons.length) {
        hasShapes = true;
        data = data.concat(await this.getPolygons(type));
      }

      if (!hasShapes) {
        const { items, limitReached } = await this.getProjects(type);
        data = data.concat(items);
        this.isLimited = limitReached;
      }

      if (type === 'layers' || type === 'charts') {
        if (!data.length && !this.islimited) {
          console.info('Empty data for censo query');
        }
      } else if (type === 'report') {
        if (!data.length) {
          console.info('Empty data for censo query');
        }
      }

      return {
        data,
        isLimited: this.isLimited,
      };
    } catch (e: any) {
      console.error(e.message);
      return { error: 'erro' };
    }
  }

  public plotCensusPolygons(censoItems: CensoItem[], type): void {
    const map = this.map.getMap();

    if (!map) {
      console.error('Failed to plot polygon on map:', map);
      return;
    }

    this.total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (const item of censoItems) {
      const geoCensusSector = JSON.parse(item.geo_census_sector) as GeoCensusSector;
      const paths = geoCensusSector.coordinates[0][0].map((i) => ({ lng: i[0], lat: i[1] }));

      let color = this.checkPolygonColor(type, item);

      const polygon = new window.google.maps.Polygon({
        paths,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.45,
      });

      this.polygons.push(polygon);
      polygon.setMap(map);
    }
  }

  public remove() {
    for (const item of this.polygons) {
      item.setMap(null);
    }
  }

  private async getCount(item: any): Promise<any> {
    const comm = new Communication(this.$store.state.Session.accessToken);
    const response = await comm.getBackendData('censusPolygonCount', 'POST', item);
    const json = (await response.json()) as ResponseJson;

    if (json.records && json.records.length > 0 && json.records[0].count) {
      return json.records[0].count;
    } else {
      return 0;
    }
  }

  private async getCircleData(lat: number, lng: number, radius: number, type: DataType): Promise<any> {
    let statesCities = this.getProjectsStatesCities();

    if (!statesCities) {
      const { city, stateAcronym } = await this.getRedPinStateCity(lat, lng);
      statesCities = `${stateAcronym}-${city}`;
    }

    const params: any = {
      geo: `SRID=4326;POINT(${lng} ${lat})`,
      radius: Math.floor(radius).toString(),
      statesCities,
    };

    if (type === 'layers' || type === 'charts') {
      const url = type === 'layers' ? 'censusLayersCircle' : 'censusChartsCircle';
      const comm = new Communication(this.$store.state.Session.accessToken);
      const response = await comm.getBackendData(url, 'POST', params);
      const json = (await response.json()) as ResponseJson;
      return json.records;
    } else if (type === 'report') {
      params.type = 'circle';
      return params;
    }
  }

  private async getPolygonData(polygon: Polygon, type: DataType): Promise<any> {
    const params: any = {
      geo: `POLYGON((${polygon.data.map((i) => `${i[0]} ${i[1]}`).toString()}))`,
    };

    if (type === 'layers' || type === 'charts') {
      if (await this.shallApplyLimit(params)) {
        this.isLimited = true;
        return [];
      } else {
        const url = type === 'layers' ? 'censusLayersPolygon' : 'censusChartsPolygon';
        const comm = new Communication(this.$store.state.Session.accessToken);
        const response = await comm.getBackendData(url, 'POST', params);
        const json = (await response.json()) as ResponseJson;
        return json.records;
      }
    } else if (type === 'report') {
      params.type = 'polygon';
      return params;
    }
  }

  private async getCircles(type: DataType): Promise<CensoItem[]> {
    let items: CensoItem[] = [];

    for (const circle of this.censoQuery.circles) {
      const { lat, lng, radius } = circle;
      items = items.concat(await this.getCircleData(lat, lng, radius, type));
    }

    return items;
  }

  private async getRedPinStateCity(lat: number, lng: number): Promise<{ city: string; stateAcronym: string }> {
    const comm = new Communication(this.$store.state.Session.accessToken);
    const data = await comm.getAddressesByLatLng(lat, lng);
    const { city, stateAcronym } = await data.json();
    return { city, stateAcronym };
  }

  private async getPolygons(type: DataType): Promise<CensoItem[]> {
    let items: CensoItem[] = [];

    for (const polygon of this.censoQuery.polygons) {
      items = items.concat(await this.getPolygonData(polygon, type));
    }

    return items;
  }

  private async getProjects(type: DataType): Promise<CensoItem[]> {
    let items: CensoItem[] = [];
    const projects = this.projectPropertiesStore.data;
    const limit = 50;
    let limitReached = false;

    if (type === 'layers' || type === 'charts') {
      if (projects.length < LIMITED_CENSO_DATA) {
        for (const project of projects) {
          const { latitude, longitude } = project;
          const radius = 1000;
          items = items.concat(await this.getCircleData(latitude, longitude, radius, type));
        }
      } else {
        this.isLimited = true;
        limitReached = true;
      }

      return { items, limitReached };
    } else if (type === 'report') {
      for (const project of projects) {
        const { latitude, longitude } = project;
        const radius = 1000;
        items = items.concat(await this.getCircleData(latitude, longitude, radius, type));
      }

      if (items.length > limit) {
        items = items.slice(0, limit);
        limitReached = true;
      }

      return { items, limitReached };
    }
  }

  // Returns "UF-CITY,UF-CITY,UF-CITY"
  private getProjectsStatesCities(): string {
    const statesCities = this.projectPropertiesStore.getDistinctStatesCities();
    return statesCities.map((i) => `${i.state}-${i.city}`).toString();
  }

  private checkPolygonColor(type, item): string {
    let color = '#cfd4dd';

    if (type.title == 'Renda Domiciliar Censo 2010') {
      if (item.renda_dom == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.renda_dom > 0 && item.renda_dom <= 1500) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.renda_dom > 1500 && item.renda_dom <= 5000) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.renda_dom > 5000 && item.renda_dom <= 10000) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.renda_dom > 10000 && item.renda_dom <= 18000) {
        color = '#9ce6f9';
        this.total[4]++;
      } else if (item.renda_dom > 18000 && item.renda_dom <= 25000) {
        color = '#f0e6ff';
        this.total[5]++;
      } else if (item.renda_dom > 25000 && item.renda_dom <= 50000) {
        color = '#ede000';
        this.total[6]++;
      } else if (item.renda_dom > 50000 && item.renda_dom <= 900000) {
        color = '#4f7942';
        this.total[7]++;
      }
    }

    if (type.title == 'Renda Domiciliar SM Censo 2010') {
      if (item.renda_dom_sm == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.renda_dom_sm > 0 && item.renda_dom_sm <= 3) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.renda_dom_sm > 3 && item.renda_dom_sm <= 5) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.renda_dom_sm > 5 && item.renda_dom_sm <= 7) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.renda_dom_sm > 7 && item.renda_dom_sm <= 9) {
        color = '#9ce6f9';
        this.total[4]++;
      } else if (item.renda_dom_sm > 9 && item.renda_dom_sm <= 12) {
        color = '#f0e6ff';
        this.total[5]++;
      } else if (item.renda_dom_sm > 12 && item.renda_dom_sm <= 20) {
        color = '#ede000';
        this.total[6]++;
      } else if (item.renda_dom_sm > 20 && item.renda_dom_sm <= 30) {
        color = '#4f7942';
        this.total[7]++;
      } else if (item.renda_dom_sm > 30 && item.renda_dom_sm <= 40) {
        color = '#0071f2';
        this.total[8]++;
      } else if (item.renda_dom_sm > 40 && item.renda_dom_sm <= 65) {
        color = '#88b6c9';
        this.total[9]++;
      } else if (item.renda_dom_sm > 65 && item.renda_dom_sm <= 266) {
        color = '#ee4dfa';
        this.total[10]++;
      }
    }

    if (type.title == 'Domicílios Alugados Censo 2010') {
      if (item.dom_alugados == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.dom_alugados > 0 && item.dom_alugados <= 20) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.dom_alugados > 20 && item.dom_alugados <= 60) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.dom_alugados > 60 && item.dom_alugados <= 120) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.dom_alugados > 120 && item.dom_alugados <= 1200) {
        color = '#9ce6f9';
        this.total[4]++;
      }
    }

    if (type.title == 'Habitantes por Km² Censo 2010') {
      if (item.hab_km2 == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.hab_km2 > 0 && item.hab_km2 <= 8000) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.hab_km2 > 8000 && item.hab_km2 <= 16000) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.hab_km2 > 16000 && item.hab_km2 <= 24000) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.hab_km2 > 24000 && item.hab_km2 <= 32000) {
        color = '#9ce6f9';
        this.total[4]++;
      } else if (item.hab_km2 > 32000 && item.hab_km2 <= 48000) {
        color = '#f0e6ff';
        this.total[5]++;
      } else if (item.hab_km2 > 48000 && item.hab_km2 <= 900000) {
        color = '#ede000';
        this.total[6]++;
      }
    }

    if (type.title == 'Número de Domicílios Censo 2010') {
      if (item.qtt_dom == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.qtt_dom > 0 && item.qtt_dom <= 200) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.qtt_dom > 200 && item.qtt_dom <= 400) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.qtt_dom > 400 && item.qtt_dom <= 800) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.qtt_dom > 800 && item.qtt_dom <= 1870) {
        color = '#9ce6f9';
        this.total[4]++;
      }
    }

    if (type.title == 'Renda dos Responsáveis Censo 2010') {
      if (item.renda_resp == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.renda_resp > 0 && item.renda_resp <= 1500) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.renda_resp > 1500 && item.renda_resp <= 5000) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.renda_resp > 5000 && item.renda_resp <= 10000) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.renda_resp > 10000 && item.renda_resp <= 18000) {
        color = '#9ce6f9';
        this.total[4]++;
      } else if (item.renda_resp > 18000 && item.renda_resp <= 25000) {
        color = '#f0e6ff';
        this.total[5]++;
      } else if (item.renda_resp > 25000 && item.renda_resp <= 50000) {
        color = '#ede000';
        this.total[6]++;
      } else if (item.renda_resp > 50000 && item.renda_resp <= 900000) {
        color = '#4f7942';
        this.total[7]++;
      }
    }

    if (type.title == 'Total de Apartamentos Censo 2010') {
      if (item.aptos == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.aptos > 0 && item.aptos <= 50) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.aptos > 50 && item.aptos <= 160) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.aptos > 160 && item.aptos <= 320) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.aptos > 320 && item.aptos <= 800) {
        color = '#9ce6f9';
        this.total[4]++;
      }
    }

    if (type.title == 'Habitantes por Domicílio Censo 2010') {
      if (item.hab_dom == 0) {
        color = '#cfd4dd';
        this.total[0]++;
      } else if (item.hab_dom > 0 && item.hab_dom <= 2.5) {
        color = '#ffbb73';
        this.total[1]++;
      } else if (item.hab_dom > 2.5 && item.hab_dom <= 3) {
        color = '#8ce563';
        this.total[2]++;
      } else if (item.hab_dom > 3 && item.hab_dom <= 4.5) {
        color = '#f48787';
        this.total[3]++;
      } else if (item.hab_dom > 4.5 && item.hab_dom <= 1200) {
        color = '#9ce6f9';
        this.total[4]++;
      }
    }

    return color;
  }

  public getTotalFaixas() {
    return this.total;
  }

  private async shallApplyLimit(params): boolean {
    if (this.$store.state.Filter.isPolygonDrawn) {
      return false;
    }

    const comm = new Communication(this.$store.state.Session.accessToken);
    const response = await comm.getBackendData('count_census_intersect', 'POST', params);
    const json = (await response.json()) as ResponseJson;
    return json?.records?.[0]?.count > LIMITED_CENSO_DATA_HAS_SHAPES;
  }
}
