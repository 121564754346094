//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Utils from '~/assets/js/utils';
import NavHeader from './NavHeader.vue';
import NavBody from './NavBody.vue';
import NavFooter from './NavFooter.vue';
import './css/indexDesk.scss';
import './css/indexMobile.scss';

import { chemistryDialogV2 } from 'chemistry-vue';

export default {
  name: 'nav-menu',

  props: ['user-destination'],

  components: {
    'c-dialog': chemistryDialogV2,
    'nav-header': NavHeader,
    'nav-body': NavBody,
    'nav-footer': NavFooter,
  },

  data() {
    return {
      utils: new Utils(),
      showNavBar: true,
      isInMobile: false,
      oldActive: null,
      showLogoffModal: false,
    };
  },

  mounted() {
    this.isInMobile = this.utils.mobileCheck();

    // autoclose in small width screens
    if (!this.isInMobile && window.innerWidth <= 1100) {
      this.$store.commit('Menu/setFirst', false);
    }
  },

  methods: {
    toggleOpen() {
      let tmpStatus = this.$store.state.Menu.firstLevel;
      this.$store.commit('Menu/setFirst', !tmpStatus);
    },

    closeMobile() {
      if (this.isInMobile) {
        this.$store.commit('Menu/setFirst', false);
      }
    },

    logoutRequest(oldActive) {
      this.showLogoffModal = true;
      this.oldActive = oldActive;
    },

    cancelLogoff() {
      this.$store.commit('Menu/setActive', this.oldActive);
      this.showLogoffModal = false;
    },

    executeLogoff() {
      this.$store.commit('Session/setDeactive');
      localStorage.setItem('token', '');
      this.$router.push({ path: '/' });
      this.showLogoffModal = false;
      this.$store.commit('Session/setNewSession');
    },
  },

  watch: {
    '$store.state.Session.menu': {
      handler: function (val) {
        console.log(val);
      },
      deep: true,
    },

    '$store.state.Menu.currentActive': {
      handler: function (val) {
        if (this.isInMobile) {
          this.toggleOpen();
        }
      },
      deep: true,
    },

    '$store.state.Menu.biggerThanScreen': {
      handler: function (val) {
        console.log(val);
      },
      deep: true,
    },
  },
};
