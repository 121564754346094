export default {
  setOpen(state, payload) {
    state.isOpen = payload;
  },

  setIdNumber(state, payload) {
    state.idNumber = payload;
  },

  setFichaTecnica(state, payload) {
    state.fichaTecnica = payload;
  },

  setTabelaVendas(state, payload) {
    state.tabelaVendas = payload;
  },

  setMonitoramento(state, payload) {
    state.monitoramento = payload;
  },

  setTableChart(state, payload) {
    state.tableChart = payload;
  },
};
