let date = new Date();
let ano = date.getFullYear();

export const state = () => ({
  id: null,
  id_source: null,
  id_project_phase: 100,
  date_created: '',
  date_updated: '',
  id_user_created: '',
  id_user_updated: '',
  name: '',
  edifice_name: '',
  enterpriseFaseado: '02',
  phase_comment: '',
  id_project_type: 100,
  launch_year: ano,
  launch_month: 100,
  date_delivery: '',
  date_launch_expected: '',
  num_blocks: '',
  id_research: 100,
  research: null,
  txtCep: '',
  txtNroEmpreendimento: null,
  txtLogradouro: '',
  txtBairro: '',
  txtCidade: '',
  txtEstado: '',
  txtDistrito: '',
  txtZonaValor: '',
  website: '',
  comment_building: '',
  latitude: '',
  longitude: '',
  location: [],
  buildersObj: {
    canAdd: true,
    canThrash: false,
  },
  builderLines: [
    {
      id: 1,
      data: null,
    },
  ],
  developersObj: {
    canAdd: true,
    canThrash: false,
  },
  developerLines: [
    {
      id: 1,
      data: null,
    },
  ],
  realEstateObj: {
    canAdd: true,
    canThrash: false,
  },
  realEstateLines: [
    {
      id: 1,
      data: null,
    },
  ],
  builderData: [],
  developerData: [],
  realEstateData: [],
  infraArray: [],
  ind_cub: '',
  ind_tax: '',
  ind_cub_h82n: '',
  ind_incc: '',
  ind_cub_igpm: '',
  indSelGrupo: 100,
  id_launch_pattern: 100,
  indData: '',
  land_area: '',
  area_total: '',
  ind_vgv_brl: '',
  property: [
    {
      name: '',
      id_building_type: 100,
      quantity_type: null,
      bedroom: null,
      bathroom: null,
      washroom: null,
      suite: null,
      garage: null,
      garage_covered: null,
      garage_uncovered: null,
      area_util: null,
      area_total: null,
      comment_values: '',
      txtValUnidade: '',
      unitValTabUtil: 0,
      unitValTabTotal: 0,
      unity_incc_di: 0,
      area_util_incc_di: 0,
      unitValTabTotalIncc: 0,
      unitValTabUnidadeCub: 0,
      area_util_cub: 0,
      unitValTabTotalCub: 0,
      unitValTabUnidadeIgpm: 0,
      area_util_igpm: 0,
      unitValTabTotalIgpm: 0,
      ind_vgv_brl: '0,00',
    },
  ],
  propertyControls: [
    {
      save: false,
      edit: false,
    },
  ],
  unitValCanAdd: true,
  unitValCanDel: false,
  num_units: null,
  pavement_quantity: null,
  pavement_unity: null,
  num_elevator: null,
  variable_grid: 0,
  variable_grid_desc: '',
  viewable: false,
  project_address: null,
  zone: null,
  photo: null,
  project_feature: [],
  id_financing: '9999',
  draft: true,
  project_bank: [],
  id_value_zone: 0,
  // loadedAddress: {
  //   logradouro: '',
  //   bairro: '',
  //   cidade: '',
  //   estado: '',
  //   distrito: '',
  //   zona: '',
  // },
});
