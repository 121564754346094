import { render, staticRenderFns } from "./index.vue?vue&type=template&id=30ee6210&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavHeader: require('/runner/_volume/ramdisk/data-olx/data-olx/components/NavMenu/NavHeader.vue').default,NavBody: require('/runner/_volume/ramdisk/data-olx/data-olx/components/NavMenu/NavBody.vue').default,NavFooter: require('/runner/_volume/ramdisk/data-olx/data-olx/components/NavMenu/NavFooter.vue').default})
