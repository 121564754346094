//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import './css/itemDesk.scss';
import './css/itemMobile.scss';
import InternalSubMenu from './internal/InternalSubBody.vue';
import ExternalLevelController from './external/ExternalLevelController.vue';

export default Vue.extend({
  name: 'NavItem',

  components: {
    'internal-sub': InternalSubMenu,
    'level-controller': ExternalLevelController,
  },

  props: ['item'],

  data() {
    return {
      showHideExternalMenu: false,
    };
  },

  methods: {
    selectItem() {
      if (this.item.enabled) {
        // if has a path, go to it
        if (this.item.path && this.item.path !== '/logout') {
          this.$store.commit('Menu/setItemClose', this.item);
          const navItem = document.getElementById(`nav-item-${this.item.id}`);
          navItem.click();
          this.$store.commit('Menu/setActive', this.item);
          // if dont have a path, open sub menu
        } else if (this.item.path === '/logout') {
          this.$emit('logout', this.$store.state.Menu.currentActive);
          this.$store.commit('Menu/setActive', this.item);
        } else {
          if (this.item.open === true) {
            this.$store.commit('Menu/setItemClose', this.item);
          } else {
            this.$store.commit('Menu/setItemOpen', this.item);
          }

          if (this.item.id === 13) {
            this.showHideExternalMenu = !this.showHideExternalMenu;
          }
        }
      }
    },

    closeSubmenu() {
      if (!this.item.active) {
        this.$store.commit('Menu/setItemClose', this.item);
      }

      // external menu must be closed too, even if the item is active
      if (!this.$store.state.Menu.firstLevel && this.item.subMenus) {
        this.$store.commit('Menu/setItemClose', this.item);
      }

      if (this.item.id === 13) {
        this.$store.commit('Menu/setItemClose', this.item);
      }
    },
  },
});
