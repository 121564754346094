//
//
//
//
//
//

import Vue from 'vue';
import ExternalSubMenu from './ExternalSubBody.vue';
import ExternalPenultimate from './ExternalPenultimateBody.vue';

export default Vue.extend({
  name: 'ExternalLevelController',

  props: ['item'],

  components: {
    'external-sub': ExternalSubMenu,
    'external-penultimate': ExternalPenultimate,
  },

  computed: {
    thirdLevel() {
      let level = false;

      this.item.subMenus.some((subMenu) => {
        if (subMenu.subMenus && subMenu.subMenus.length > 0) {
          level = true;
        }
      });

      return level;
    },
  },

  methods: {
    closeSubmenu() {
      this.$emit('closeSubmenu');
    },
  },
});
