import { CLEAR_STATE } from './index';

export default {
  toggle(state) {
    state.showFiltersFlag = !state.showFiltersFlag;
  },

  clearFilters(state) {
    state.filterValues = JSON.parse(JSON.stringify(CLEAR_STATE));
    state.isFilterApplied = false;
  },

  toggleShowMoreFilters(state) {
    state.showMoreFiltersFlag = !state.showMoreFiltersFlag;
  },

  selectOption(state, { type, option }) {
    if (Array.isArray(option)) {
      option.forEach((opt) => {
        var tmpOpt = opt;

        if (type == 'district') {
          tmpOpt = opt.district;
        }

        if (type == 'neighborhood') {
          tmpOpt = opt.neighbor;
        }

        if (type == 'id_developer') {
          state.filterValues.developerObj.push(opt);
        }

        if (!state.filterValues[type].includes(tmpOpt.id || tmpOpt)) {
          state.filterValues[type].push(tmpOpt.id || tmpOpt);
          return;
        }
        // Lógica para lidar com cada opção individualmente
        // Por exemplo, adicionar a opção a uma lista de opções selecionadas
      });
    } else {
      var tmpOpt = option;

      if (type == 'district') {
        tmpOpt = option.district;
      }

      if (type == 'neighborhood') {
        tmpOpt = option.neighbor;
      }

      if (type == 'id_developer') {
        state.filterValues.developerObj.push(option);
      }

      if (!state.filterValues[type].includes(tmpOpt.id || tmpOpt)) {
        state.filterValues[type].push(tmpOpt.id || tmpOpt);
        return;
      }
    }
  },

  clearOption(state, { type, option }) {
    var tmpOpt = option;

    if (type == 'district') {
      tmpOpt = option.district;
    }

    if (type == 'neighborhood') {
      tmpOpt = option.neighbor;
    }

    if (type == 'id_developer') {
      state.filterValues.developerObj = state.filterValues.developerObj.filter((item) => {
        if (item.id != option.id) {
          return item;
        }
      });
    }

    state.filterValues[type] = state.filterValues[type].filter((item) => {
      if (item != (tmpOpt.id || tmpOpt)) {
        return item;
      }
    });
  },

  selectRangeValues(state, { type, minValue, maxValue }) {
    state.filterValues[type] = [parseInt(minValue), parseInt(maxValue)];
  },

  selectValue(state, { type, value }) {
    state.filterValues[type] = value;
  },

  setFieldValue(state, { value }) {
    state.fieldValue = value;
  },

  setFieldId(state, value) {
    state.fieldId = value;
  },

  setAutocompleteTypeSelected(state, type) {
    state.autocompleteTypeSelected = type;
  },

  toggleShowAutocomplete(state, show) {
    state.showAutocompleteContainer = show;
  },

  setToggleState(state, value) {
    state.showFiltersFlag = value;
  },

  setLocationBlock(state, value) {
    state.locationBlock = value;
  },

  setPropertyBlock(state, value) {
    state.propertyBlock = value;
  },

  setPolygonDrawn(state, value) {
    state.isPolygonDrawn = value;
  },

  setFilterApplied(state, value) {
    state.isFilterApplied = value;
  },

  setAutoCompleteOriginalSearch(state, value) {
    state.autoCompleteOriginalSearch = value;
  },
};
