//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//@ts-nocheck
import './styleDesk.scss';
import './styleMobile.css';

import Vue from 'vue';
import Censo from '~/assets/js/Censo';
import Communication from '~/assets/js/Communication';
import Login from '~/pages/Login/index.vue';
import changePass from '~/pages/Login/passwordRegistration.vue';
import GoogleAnalytics from '../assets/js/GoogleAnalytics';
import Map from '../assets/js/Map';
import ProjectPropertiesStore from '../assets/js/ProjectPropertiesStore';
import Tracking from '../assets/js/Tracking';
import getOrCreateUuidCookie from '../assets/js/uuid';
import NavMenu from '../components/NavMenu/index.vue';
import Utils from '~/assets/js/utils';

import { chemistryDialogV2 } from 'chemistry-vue';

export const colors = {
  black: '#000',
  grey: '#F6F4FD',
  mediumGrey: '#999999',
  darkGrey: '#4A4A4A',
  darkerGrey: '#8F92A1',
  white: '#FFF',
  purple: '#6E0AD6',
  secondaryBlack: '#4C4C4E',
  neutral: '#E5E5E5',
  neutralLight: '#F6F6F6',
  neutralLighter: '#F9F9F9',
  secondaryPurple: '#F0E6FF',
  feedbackError: '#F48787',
  feedbackAttention: '#F9AF27',
};

export default Vue.extend({
  components: {
    'nav-menu': NavMenu,
    'login-page': Login,
    'change-pass': changePass,
    'c-dialog': chemistryDialogV2,
  },

  data() {
    return {
      fullscreen: false,
      isStreetView: false,
      destination: '/geoimovel',
      validDestination: [
        '/overview',
        '/geoimovel',
        '/QuantoVale/quantovale',
        '/QuantoValePro',
        '/Ferramentas/ec2',
        '/Ferramentas/geoCep',
        '/Ferramentas/upload',
        '/Ferramentas/ipca',
        '/Estudos/estudoDeMercado',
        '/Estudos/pesquisaQualitativa',
        '/Estudos/perfilDoLead',
        '/Estudos/ondeVale',
        '/Estudos/lupa',
        '/incluirestudo',
        '/consultaestudo',
        '/cadastroimovel',
        '/cadastrocliente',
        '/cadastroempresacliente',
        '/Consultoria/ondeVale',
        '/Consultoria/pesquisa/filtros',
        '/ParecerTecnico/parecerTecnico',
        '/ParecerTecnicoPrintTemplate/parecerTecnico',
        '/ParecerTecnicoPrintTemplate/parecerTecnicoB3',
        '/suporte',
        '/configuracao',
      ],
      today: new Date(),
      utils: new Utils(),
    };
  },

  // before create, we need to check the user situation:
  beforeCreate() {
    let intentDestination = window.location.pathname.toString();
    let comm = new Communication();

    let query = window.location.search;
    let urlParams = new URLSearchParams(query);
    // Get the token from url (user came from old platform)
    let urlToken = urlParams.get('token');

    // Or get e-mail and pin to change the password
    let urlChangeUser = urlParams.get('email');
    let urlChangePass = urlParams.get('pin');
    let urlChangeType = urlParams.get('intent');

    // if have a new token (from old platform), update in the storage and clean the nav URL bar.
    if (urlToken) {
      localStorage.setItem('token', urlToken);
      window.history.pushState({}, document.title, '/' + '');
    }

    // put the stuff in obj is easer to send via stringfy latter...
    let tokenObj = {
      token: localStorage.getItem('token'),
    };

    let changePassObj = {
      user: urlChangeUser,
      token: urlChangePass,
      intent: urlChangeType ? true : false,
    };

    if (changePassObj && changePassObj.token != undefined && changePassObj.token != '') {
      this.$store.commit('Session/setChangePassUser', changePassObj.user);
      this.$store.commit('Session/setChangePassToken', changePassObj.token);
      this.$store.commit('Session/setFirstAccess', changePassObj.intent);
      this.$store.commit('Session/setChangePass', true);
      //delete old token
      localStorage.setItem('token', '');
    }

    // if we have a token, we need to check if it is valid
    if (tokenObj.token != null && tokenObj.token != undefined && tokenObj.token != '') {
      this.$store.commit('Session/setOldGeo', true);

      comm.getBackendData('validToken', 'POST', tokenObj, null, false).then((res) => {
        //token is ok.
        if (res && res.status == 200) {
          res.json().then((data) => {
            let sessionObj = {
              accessToken: tokenObj.token,
              name: data.fullname,
              eMail: data.username,
              id: data.id,
              email: data.username,
              roles: data.roles,
              uuid: data.uuid,
            };

            this.$store.commit('Session/setRoles', data.roles);

            //check user profiles
            let comm2 = new Communication(tokenObj.token);
            comm2.getBackendData('checkUserAccess', 'GET', null, sessionObj.id).then((res) => {
              if (res && res.status == 200) {
                res.json().then((data) => {
                  // delete change pass info (if there is some...)
                  this.$store.commit('Session/setChangePassToken', null);
                  this.$store.commit('Session/setChangePass', false);

                  // set user profile
                  let profileArray = [];
                  let expiredArray = [];
                  let isOlx = false;
                  let primeYield = false;

                  data.records.forEach((item) => {
                    let strEnd = '99999999';

                    if (item.id_status == 1) {
                      profileArray.push(item.id_product);
                    } else if (item.id_status != 1 && item.date_end_period) {
                      expiredArray.push(item.id_product);
                    }

                    if (item.adm_code == 32 && sessionObj.roles.indexOf('ADMIN') != -1) {
                      isOlx = true;
                    }

                    if (item.adm_code == 100023) {
                      primeYield = true;
                    }
                  });

                  isOlx ? profileArray.push(99) : null;
                  primeYield ? profileArray.push(98) : null;

                  if (profileArray.length > 0 || expiredArray.length > 0) {
                    // activate session and send to first page;
                    this.activeSession(sessionObj, intentDestination);
                    this.$store.commit('Session/setUserProfile', profileArray);
                    this.$store.commit('Session/setProfExpired', expiredArray);
                  } else {
                    this.$store.commit('Session/setProfileError', true);
                  }
                });
              } else {
                this.$store.commit('Session/setProfileError', true);
                this.$store.commit('Session/setDeactive');
                localStorage.setItem('token', '');
              }
            });
          });
        } else {
          // token is not ok, deactive session and delete it from localstorage.
          this.$store.commit('Session/setDeactive');
          localStorage.setItem('token', '');
        }
      });
    }

    // Types declared on file "custom.d.ts"
    const anonymousId = getOrCreateUuidCookie();
    this.$store.commit('Session/setAnonymousId', anonymousId);
    const env = process.env.NUXT_ENV_AMBIENTE;

    const projectPropertiesStore = new ProjectPropertiesStore();
    const map = new Map();

    Vue.prototype.$globals = {
      googleAnalytics: new GoogleAnalytics(this.$store),
      map,
      markers: [],
      projectPropertiesStore,
      streetMarker: null,
      tracking: new Tracking(anonymousId, env),
      censo: new Censo(this.$store, map, projectPropertiesStore),
    };
  },

  created() {
    this.$nuxt.$on('fullscreen', () => {
      this.fullscreen = true;
    });
  },

  methods: {
    async activeSession(sessionObj, intentDestination) {
      await this.$store.commit('Session/setActive', sessionObj);

      //we need to change destination here, in future will be '/overview'
      let sendUserTo = '/geoimovel';
      let lupaOrGeo = '';

      if (this.utils.mobileCheck()) {
        if (this.$store.state.Session.profiles.indexOf(2) > -1) {
          lupaOrGeo = '/QuantoVale/quantovale';
        } else {
          lupaOrGeo = '/suporte';
        }
        this.destination = lupaOrGeo;
      } else {
        if (this.$store.state.Session.profiles.indexOf(1) > -1 || this.$store.state.Session.profiles.indexOf(11) > -1) {
          lupaOrGeo = '/geoimovel';
        } else if (this.$store.state.Session.profiles.indexOf(5) > -1) {
          lupaOrGeo = '/Estudos/lupa';
        } else if (this.$store.state.Session.profiles.indexOf(98) > -1) {
          lupaOrGeo = 'ParecerTecnico/parecerTecnico';
        } else {
          lupaOrGeo = '/suporte';
        }
      }

      //remove valid destination from the list when user is in mobile
      if (this.utils.mobileCheck()) {
        let tmpArray = [];
        this.validDestination.forEach((item) => {
          this.$store.state.Menu.menuItems.forEach((menu) => {
            if (menu.path == item) {
              if (menu.mobile) {
                tmpArray.push(item);
              }
            }
            if (menu.subMenus) {
              menu.subMenus.forEach((subMenu) => {
                if (subMenu.path == item) {
                  if (subMenu.mobile) {
                    tmpArray.push(item);
                  }
                }
                if (subMenu.subMenus) {
                  subMenu.subMenus.forEach((subSubMenu) => {
                    if (subSubMenu.path == item) {
                      if (subSubMenu.mobile) {
                        tmpArray.push(item);
                      }
                    }
                  });
                }
              });
            }
          });
        });
        this.validDestination = new Array(...tmpArray);
      }

      // for now, users always have one of this two profiles, lupa or geoimovel
      if (this.validDestination.indexOf(intentDestination) == -1) {
        sendUserTo = lupaOrGeo;
      }
      // this is the profiles we have for now
      // 0 = overview (only in front-end);
      // 1 = geoimovel (database);
      // 2 = quantoVale (database);
      // 3 = ondeVale (database);
      // 4 = laudo (database);
      // 5 = lupa (database);
      // 6 = quantoValePro (database);

      if (this.utils.mobileCheck()) {
        switch (intentDestination) {
          case '/QuantoVale/quantovale':
            this.$store.state.Session.profiles.indexOf(2) > -1
              ? (sendUserTo = '/QuantoVale/quantovale')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/QuantoValePro':
            this.$store.state.Session.profiles.indexOf(6) > -1
              ? (sendUserTo = '/QuantoValePro')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/ParecerTecnico/parecerTecnico':
            this.$store.state.Session.profiles.indexOf(4) > -1
              ? (sendUserTo = '/ParecerTecnico/parecerTecnico')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/suporte':
            sendUserTo = '/suporte';
            break;
          default:
            sendUserTo = lupaOrGeo;
            break;
        }
      } else {
        switch (intentDestination) {
          case '/overview':
            this.$store.state.Session.profiles.indexOf(0) > -1 ? (sendUserTo = '/overview') : (sendUserTo = lupaOrGeo);
            break;
          case '/geoimovel':
            this.$store.state.Session.profiles.indexOf(1) > -1 ? (sendUserTo = '/geoimovel') : (sendUserTo = lupaOrGeo);
            break;
          case '/QuantoVale/quantovale':
            this.$store.state.Session.profiles.indexOf(2) > -1
              ? (sendUserTo = '/QuantoVale/quantovale')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/Estudos/ondeVale':
            this.$store.state.Session.profiles.indexOf(3) > -1
              ? (sendUserTo = '/Estudos/ondeVale')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/ParecerTecnico/parecerTecnico':
            this.$store.state.Session.profiles.indexOf(4) > -1
              ? (sendUserTo = '/ParecerTecnico/parecerTecnico')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/ParecerTecnicoPrintTemplate/parecerTecnico':
            this.$store.state.Session.profiles.indexOf(4) > -1
              ? (sendUserTo = '/ParecerTecnicoPrintTemplate/parecerTecnico')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/ParecerTecnicoPrintTemplate/parecerTecnicoB3':
            this.$store.state.Session.profiles.indexOf(4) > -1
              ? (sendUserTo = '/ParecerTecnicoPrintTemplate/parecerTecnicoB3')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/Estudos/lupa':
            this.$store.state.Session.profiles.indexOf(5) > -1
              ? (sendUserTo = '/Estudos/lupa')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/QuantoValePro':
            this.$store.state.Session.profiles.indexOf(6) > -1
              ? (sendUserTo = '/QuantoValePro')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/cadastroimovel':
            this.$store.state.Session.profiles.indexOf(99) > -1
              ? (sendUserTo = '/cadastroimovel')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/cadastroimovel/editar':
            this.$store.state.Session.profiles.indexOf(99) > -1
              ? (sendUserTo = '/cadastroimovel/editar')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/cadastroimovel/monitoramento':
            this.$store.state.Session.profiles.indexOf(99) > -1
              ? (sendUserTo = '/cadastroimovel/monitoramento')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/cadastrocliente':
            this.$store.state.Session.profiles.indexOf(99) > -1
              ? (sendUserTo = '/cadastrocliente')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/cadastroempresacliente':
            this.$store.state.Session.profiles.indexOf(99) > -1
              ? (sendUserTo = '/cadastroempresacliente')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/Consultoria/ondeVale':
            this.$store.state.Session.profiles.indexOf(99) > -1
              ? (sendUserTo = '/Consultoria/ondeVale')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/Consultoria/pesquisa/filtros':
            this.$store.state.Session.profiles.indexOf(99) > -1
              ? (sendUserTo = '/Consultoria/pesquisa/filtros')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/Ferramentas/geoCep':
            this.$store.state.Session.profiles.indexOf(98) > -1
              ? (sendUserTo = '/Ferramentas/geoCep')
              : (sendUserTo = lupaOrGeo);
            break;
          case '/suporte':
            sendUserTo = '/suporte';
            break;
          case '/configuracao':
            this.$store.state.Session.profiles.indexOf(0) > -1
              ? (sendUserTo = '/configuracao')
              : (sendUserTo = lupaOrGeo);
            break;
          default:
            // block all other?? If not, remove the line bellow...
            sendUserTo = lupaOrGeo;
            break;
        }
      }

      intentDestination = sendUserTo;
      // destination var is needed to "mark" the menu when user access address via URL navigation bar...
      this.destination = sendUserTo;

      this.$store.commit('Session/setNavigation', intentDestination);

      this.$router.push({ path: intentDestination, query: this.$route.query });
    },

    closeDialog() {
      this.$store.commit('Session/setProfileError', false);
      this.$store.commit('Session/setDeactive');
    },
  },

  watch: {
    '$store.state.GoogleMaps.isStreetView': {
      handler: function (val) {
        this.isStreetView = val;
      },
      deep: true,
    },
  },
});
