export default {
  toggle(context) {
    context.commit('toggle');
  },

  selectOption(context, { type, option }) {
    context.commit('selectOption', { type, option });
  },

  clearOption(context, { type, option }) {
    context.commit('clearOption', { type, option });
  },

  setFieldValue(context, { value }) {
    context.commit('setFieldValue', { value });
  },

  selectValue(context, { type, value }) {
    context.commit('selectValue', { type, value });
  },

  clearFilters(context) {
    context.commit('clearFilters');
  },
};
