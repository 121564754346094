export const state = () => ({
  isActive: false,
  expired: false,
  userLogoff: false,
  oldGeo: false,
  firstAccess: false,
  profileError: false,
  accessToken: '',
  userName: '',
  eMail: '',
  userID: '',
  userUUID: '',
  userEmail: '',
  changePass: false,
  changePassUser: '',
  changePassToken: '',
  profiles: [0],
  profExpired: [],
  anonymousId: null,
  roles: [],
  navigation: '',
});
