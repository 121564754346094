export default {
  setMapWithOptions(state, customConfig = {}) {
    const mapConfig = {
      center: { lat: -23.56173360952944, lng: -46.64438420604292 },
      zoom: 15,
      minZoom: 6,
      maxZoom: 20,
      restriction: {
        latLngBounds: {
          north: 18,
          south: -46,
          east: -10,
          west: -96,
        },
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControl: false,
      mapTypeId: 'roadmap',
      mapTypeControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
      gestureHandling: 'greedy',
    };

    state.mapConfig = { ...mapConfig, ...customConfig };
  },

  //gestureHandling: 'cooperative'

  changeListenerFiltersApplied(state, payload) {
    state.listenerFiltersApplied = payload;
  },

  setMap(state, payload) {
    state.map = payload;
  },

  setAutocompletePredictions(state, predictions) {
    state.autocompletePredictions = predictions;
  },

  setMapTypeId(state, mapType) {
    state.mapTypeId = mapType;
  },

  setProjectPropertyAutocompletePredictions(state, predictions) {
    state.projectPropertyAutocompletePredictions = predictions;
  },

  setStreetView(state, isOn) {
    state.isStreetView = isOn;
  },

  setStreetViewObj(state, map) {
    state.streetViewObj = map;
  },

  setMiniMapWithOptions(state) {
    state.mapConfig = {
      center: { lat: -23.56173360952944, lng: -46.64438420604292 },
      zoom: 15,
      minZoom: 15,
      maxZoom: 15,
      restriction: {
        latLngBounds: {
          north: 18,
          south: -46,
          east: -10,
          west: -96,
        },
      },
      zoomControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControl: false,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControl: false,
      mapTypeId: 'roadmap',
      mapTypeControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
      gestureHandling: 'none',
    };
  },

  setLupaMapWithOptions(state) {
    state.mapConfig = {
      center: { lat: -23.56173360952944, lng: -46.64438420604292 },
      zoom: 9,
      minZoom: 6,
      maxZoom: 20,
      restriction: {
        latLngBounds: {
          north: 18,
          south: -46,
          east: -10,
          west: -96,
        },
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControl: false,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControl: false,
      mapTypeId: 'roadmap',
      mapTypeControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
      gestureHandling: 'cooperative',
    };
  },

  setSimilarMapWithOptions(state) {
    state.mapConfig = {
      zoom: 9,
      minZoom: 6,
      maxZoom: 20,
      restriction: {
        latLngBounds: {
          north: 18,
          south: -46,
          east: -10,
          west: -96,
        },
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControl: false,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControl: false,
      mapTypeId: 'roadmap',
      mapTypeControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
      gestureHandling: 'greedy',
    };
  },
};
