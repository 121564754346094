export default {
  setFullData(state, data) {
    state.fullData = data;
  },

  setSelectData(state, data) {
    state.selectData = data;
  },

  increaseUpdateCount(state) {
    state.updateCount++;
  },
};
