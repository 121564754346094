//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import ExternalSubBody from './ExternalSubBody.vue';

export default Vue.extend({
  name: 'ExternalPenultimateItem',

  components: {
    'external-sub': ExternalSubBody,
  },

  props: ['item', 'tirthLevel', 'order', 'length'],

  data() {
    return {
      showSubMenu: false,
    };
  },

  methods: {
    selectItem(item) {
      if (item && item.enabled) {
        if (item.path) {
          this.showSubMenu = false;
          const navItem = document.getElementById(`nav-penultimate-${item.id}`);
          navItem.click();
          this.$store.commit('Menu/setActive', this.item);
          this.$store.commit('Menu/setItemClose', this.item);
        } else {
          this.showSubMenu = !this.showSubMenu;
        }
      }
    },

    closeSubMenu() {
      this.showSubMenu = false;
    },
  },
});
