import Utils from './utils';

const utils = new Utils();

type Context = {
  type: 'PAGEVIEW' | 'CLICK';
  page_category?: string;
};

export default class Tracking {
  constructor(public anonymousId: string, private env: string) {}

  // @contextOptions must receive pre-set props (listed on https://olxbr.atlassian.net/wiki/spaces/TE/pages/4251582473/Modelo+de+Dados+do+Clickstream+WIP)
  // @attributes can receive custom prop names
  track(eventName: string, contextOptions: Context, attributes?: Record<string, any>) {
    if (!contextOptions.type) {
      throw new Error('Param missing on Tracking.track: contextOptions.type is required.');
    }

    // @ts-ignore
    window.Tracker?.track(eventName, attributes || {}, {
      context: {
        application: 'DATAZAP',
        platform: utils.mobileCheck() ? 'MOBILE' : 'DESKTOP',
        event_version: 'v.2',
        anonymous_id: this.anonymousId,
        page_url: document?.location?.href || '',
        referer_url: document?.referrer || '',
        device_sent_timestamp: Date.now(),
        ...contextOptions,
      },
    });

    if (this.env !== 'production') {
      console.info('Tracking:', eventName, contextOptions, attributes);
    }
  }
}
