//
//
//
//
//

import Vue from 'vue';
import internalItem from './internalItem.vue';

export default Vue.extend({
  name: 'InternalSubBody',

  props: ['subMenus'],

  components: {
    'internal-item': internalItem,
  },

  computed: {
    validSubMenu() {
      return this.subMenus.filter((menu) => !menu.hidden);
    },
  },

  methods: {
    selectItem(item, level) {
      this.$emit('selectItem', item, level);
    },
  },
});
