//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import ExternalTitle from './ExternalTitle.vue';
import ExternalPenultimateItem from './ExternalPenultimateItem.vue';

export default Vue.extend({
  name: 'ExternalPenultimate',

  components: {
    'external-title': ExternalTitle,
    'external-p-item': ExternalPenultimateItem,
  },

  props: ['item'],

  computed: {
    validSubMenu() {
      return this.item.subMenus.filter((menu) => !menu.hidden);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getStyle();
    });
  },

  methods: {
    getStyle() {
      let element = document.querySelector('.external-penultimate__container');
      let rect = element.getBoundingClientRect();
      let viewHeight = Math.max(element.clientHeight, window.innerHeight);

      let left = '80px';
      let margin = '0px';
      let top = 0;
      let bottom = 'unset';

      if (this.$store.state.Menu.firstLevel) {
        left = '321px';
      } else {
        margin = '8px 0';
      }

      if (rect.bottom + 12 - viewHeight >= 0) {
        bottom = '0px';
        top = 'unset';
      } else {
        bottom = 'unset';
        top = '0px';
      }

      element.style.bottom = bottom;
      element.style.left = left;
      element.style.margin = margin;
      element.style.top = top;
      element.style.width = '362px';
    },
  },
});
