import axios from 'axios';
import ImageType from '../../classes/ProjectImage';
import config from '../../static/data/urlEnvironment.json';

export default class Communication {
  _urlBase: Record<string, string>;
  auth: string;

  constructor(auth: string) {
    window.ambiente = process.env.NUXT_ENV_AMBIENTE;
    this._urlBase = config[window.ambiente];
    this.auth = auth;

    AbortSignal.timeout ??= function timeout(ms) {
      const ctrl = new AbortController();
      setTimeout(() => ctrl.close(), ms);
      return ctrl.signal;
    };
  }

  getEstudo(val) {
    var data = {
      parameters: val,
      num_results: 50,
    };

    return fetch(this._urlBase.estudos, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    }).then((response) => response);
  }

  getAddressesByZipCodeAndNumber(zipCode, buildingNumber) {
    return fetch(this._urlBase.redpinAddresses + `zipCode=${zipCode}&streetNumber=${buildingNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getAddressesByZipCode(zipCode) {
    return fetch(this._urlBase.redpinAddresses + `zipCode=${zipCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getAddressesByLatLng(lat: number, lng: number) {
    return fetch(this._urlBase.redpinAddresses + `latitude=${lat}&longitude=${lng}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  triggerOrder(dataOrder) {
    return fetch(this._urlBase.newEstudo + 'pedido', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(dataOrder),
    }).then((response) => response);
  }

  //removed axios.. need to test more...
  triggerWaiter(data) {
    return fetch(this.this._urlBase.newEstudo + 'briefing', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    }).then((response) => response);
  }

  triggerPolygon(formData) {
    return fetch(this.this._urlBase.newEstudo + 'poligono', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      return { uid: response.data };
    });
  }

  getCourierUrl() {
    return this._urlBase.courier;
  }

  getPointsFromCircle(coord, size, filters) {
    let compl = filters ? `&${filters}` : '';

    return fetch(`${this._urlBase.circlePointsExpression}POINT(${coord});${size}${compl}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getPointsFromPolygon(coord, filters) {
    let compl = filters ? `&${filters}` : '';
    let strCoord = coord.geometry.coordinates[0];
    let tmpArray = new Array();
    let tmpItem = '';

    strCoord.forEach((item) => {
      tmpItem = item.toString().replace(',', ' ');
      tmpArray.push(tmpItem);
    });

    return fetch(`${this._urlBase.polygonPointsExpression}POLYGON((${tmpArray.toString()}))${compl}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getPointsFromFilter(urlFilters, isUrl) {
    let url = isUrl ? urlFilters : this._urlBase.filter_search + urlFilters;

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getModalDetails(id, type: 'default' | 'backoffice') {
    let urlBase = '';

    switch (type) {
      case 'default':
        urlBase = this._urlBase.modalDetails;
        break;

      case 'backoffice':
        urlBase = this._urlBase.modalDetailsBackoffice;
        break;
    }

    return fetch(`${urlBase}?where=pp.id_source,eq,${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      signal: AbortSignal.timeout(150000),
    })
      .then((response) => response)
      .catch((err) => {
        return { error: err };
      });
  }

  getExtraModalDetails(id) {
    return fetch(this._urlBase.modalMonitoramento + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      signal: AbortSignal.timeout(150000),
    })
      .then((response) => response)
      .catch((err) => {
        return { error: err };
      });
  }

  upload(formData) {
    return axios
      .post(this._urlBase.carrierRavenUpload + 'upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.auth}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  uploadIPCA(formData) {
    return axios
      .post(this._urlBase.carrierRavenUpload + 'ipca', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.auth}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  list_geoCep(data) {
    return fetch(this._urlBase.carrierRaven + 'geoCep', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .catch((error) => error);
  }

  getQuantoVale(data) {
    return fetch(this._urlBase.carrierRaven + 'quantovale', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .catch((error) => error);
  }

  create_ec2(data) {
    return fetch(this._urlBase.carrierRaven + 'Ec2create', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .catch((error) => error);
  }

  list_ec2(data) {
    return fetch(this._urlBase.carrierRaven + 'Ec2list', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .catch((error) => error);
  }

  delete_ec2(data) {
    return fetch(this._urlBase.carrierRaven + 'Ec2delete', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .catch((error) => error);
  }

  getLaudoSantander(data) {
    return fetch(this._urlBase.carrierRaven + 'laudo', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .catch((error) => error);
  }

  getCoordinates(data) {
    return fetch(this._urlBase.carrierRaven + 'coordinates', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .catch((error) => error);
  }

  getFileFromCrud(fileName) {
    return fetch(this._urlBase.apiCrudCheckReportMap + fileName, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getGoogleEarthMap(data) {
    return fetch(this._urlBase.googleEarthMap + '?sub_unique_line=false&export=kml', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    }).then((response) => response);
  }

  //now this works is via carrier pigeon
  getReportFileFromUglyBear(data) {
    let headers = new Object();

    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth}`,
    };

    const bodyParams = {
      bucket: 'data.olx.bear.reports',
      path: 'reports/',
      file: data.fileName,
    };

    return fetch(this._urlBase.uglyBearCheckReport, {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(bodyParams),
    })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          console.log('ok');
          return response;
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch((error) => {
        let fakeError = { status: 404, message: 'Something went wrong' };
        let fakePromisse = new Promise((resolve, reject) => {
          setTimeout(() => {
            console.log('fake error', fakeError);
            resolve(fakeError);
          }, 500);
        });
        return fakePromisse;
      });
  }

  //end of reports from uglyBear
  getUFShape(state) {
    let url = `${this._urlBase.ply_state}?filter=id_state,in,${state}`;

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getCityShape(props: { city: string; stateAbbreviation?: string; stateId?: string }) {
    const { city, stateAbbreviation, stateId } = props;

    let url = `${this._urlBase.ply_city}`;

    let params = [
      {
        where: `name,in,${city}`,
      },
    ];

    if (stateAbbreviation) {
      params.push({ where: `acronym,in,${stateAbbreviation}` });
    }

    if (stateId) {
      params.push({ where: `id_ibge_state,in,${stateId}` });
    }

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(params),
    }).then((response) => {
      return response;
    });
  }

  getNeighborhoodShape(data) {
    let url = `${this._urlBase.ply_neighborhood}${data.ufSelected}&normalize_city_name=${data.city}&normalize_name=${data.neighborhood}`;

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getNeighborhoodData(city, state) {
    let url = `${this._urlBase.street}city=${city}&state=${state}`;

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getGenericFilter(route) {
    let url = this._urlBase[route];
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => response);
  }

  getConsultancySearchResults(data) {
    const url = `${this._urlBase['view_backoffice_search']}${data}`;

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    });
  }

  datazapGatewayLogin(data) {
    let url = `${this._urlBase.datazapGateway}login`;

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response;
    });
  }

  resetMail(mail) {
    let url = `${this._urlBase.resetMail}?email=${encodeURIComponent(mail)}`;

    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    }).then((response) => {
      return response;
    });
  }

  changePassword(data) {
    let url = `${this._urlBase.changePassword}`;

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response;
    });
  }

  //use this one for now on
  // to do - Change every call to this (generic) :)
  getBackendData(
    route: keyof typeof config.local,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    params: any = null,
    urlParams: string | null = null,
    isForm = false,
    signal: AbortSignal = null
  ) {
    let url = urlParams ? `${this._urlBase[route]}${urlParams}` : `${this._urlBase[route]}`;
    let body: any = null;

    if (params && !urlParams) {
      if (isForm) {
        body = params;
      } else {
        body = JSON.stringify(params);
      }
    }

    return fetch(url, {
      method: method,
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${this.auth}`,
        ...(!isForm && { 'Content-Type': 'application/json' }),
      },
      body,
      signal,
    }).then((response) => {
      return response;
    });
  }

  async getCities(state) {
    const url = `${this._urlBase.city}${state}`;

    try {
      const data = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.auth}`,
        },
      });
      return await data.json();
    } catch (e) {
      console.error('Communication error:', e);
      return [];
    }
  }

  /* Update or insert builder */
  async upsertBuilder(postData) {
    const url = this._urlBase.upsertBuilder;

    try {
      const responseData = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.auth}`,
        },
        body: JSON.stringify(postData),
      });
      return await responseData.json();
    } catch (e) {
      console.error('Communication error:', e);
      return [];
    }
  }

  /* Update or insert developer */
  async upsertDeveloper(postData) {
    const url = this._urlBase.upsertDeveloper;

    try {
      const responseData = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.auth}`,
        },
        body: JSON.stringify(postData),
      });
      return await responseData.json();
    } catch (e) {
      console.error('Communication error:', e);
      return [];
    }
  }

  /* Update or insert real estate */
  async upsertRealEstate(postData) {
    const url = this._urlBase.upsertRealEstate;

    try {
      const responseData = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.auth}`,
        },
        body: JSON.stringify(postData),
      });
      return await responseData.json();
    } catch (e) {
      console.error('Communication error:', e);
      return [];
    }
  }

  async getItemsListData<Type>(type: 'company' | 'employee'): Promise<Type[]> {
    let url = '';

    switch (type) {
      case 'company':
        url = this._urlBase.admCompanyList;
        break;

      case 'employee':
        url = this._urlBase.employeeList;
        break;
    }

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
    });
    const json = await response.json();
    return json.records;
  }

  getPerformanceChartsYears(data) {
    return fetch(this._urlBase.performanceChartsYears, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    }).then((response) => response);
  }

  async setItemFlagDeleted(type: 'company' | 'employee', id: number) {
    if (!id) {
      console.error('No ID set at setItemFlagDeleted:', id);
      return;
    }

    let url = '';

    switch (type) {
      case 'company':
        url = this._urlBase.admCompanyUpdate;
        break;

      case 'employee':
        url = this._urlBase.employeeUpdate;
        break;
    }

    url = url.replaceAll('%ID%', id.toString());

    const data = {
      fl_deleted: 'TRUE',
    };

    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
      mode: 'cors',
    });

    return response;
  }

  getPerformanceChartsMonths(data) {
    return fetch(this._urlBase.performanceChartsMonths, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth}`,
      },
      body: JSON.stringify(data),
    }).then((response) => response);
  }

  upsertProjectImage(
    props: {
      fileId?: string;
      idSource: number;
      imageDataUrl: string;
      order: number;
      type: ImageType;
      title?: string | undefined;
      subtitle?: string | undefined;
    },
    hasFile: boolean
  ) {
    const { fileId, idSource, imageDataUrl, order, type, title, subtitle } = props;

    function dataURLtoBlob(dataurl) {
      const parts = dataurl.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const array = new Uint8Array(raw.length);
      for (let i = 0; i < raw.length; i++) {
        array[i] = raw.charCodeAt(i);
      }
      return new Blob([array], { type: contentType });
    }

    function getImageTypeFromDataUrl(dataUrl) {
      const matches = dataUrl.match(/^data:(image\/[a-zA-Z+-.]+);base64,/);
      if (!matches || matches.length !== 2) {
        return 'png'; // Default
      }

      return matches[1];
    }

    const formData = new FormData();
    formData.append('idSource', idSource);
    formData.append('order', order);
    formData.append('type', type);

    typeof title !== 'undefined' && formData.append('title', title);
    typeof subtitle !== 'undefined' && formData.append('subtitle', subtitle);
    fileId && formData.append('fileId', fileId);

    if (hasFile) {
      const blob = dataURLtoBlob(imageDataUrl);
      formData.append('file', blob);
    }

    return axios.post(this._urlBase.upsertProjectImage, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.auth}`,
      },
    });
  }

  async checkQVProPDF(file: string) {
    try {
      const response = await fetch(file, { method: 'GET', mode: 'cors' });
      console.log(response);
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  }
}
