//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  name: 'NavHR',

  props: ['width'],
});
