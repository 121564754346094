import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

export default function getOrCreateUuidCookie(): string {
  const cookieName = 'anonymous_id';

  let uuid = Cookies.get(cookieName);

  if (!uuid) {
    uuid = uuidv4();

    // Store UUID on cookie for 30 days
    Cookies.set(cookieName, uuid, { expires: 30 });
  }

  return uuid;
}
