const filterDistinct = (value: any, index: any, array: any[]) => array.indexOf(value) === index;

type ProjectProperty = {
  city: string;
  id_project_property: number;
  id_source: number;
  inactive: boolean;
  latitude: number;
  longitude: number;
  shapeId: string;
  state: string;
};

/**
 * Used to set project properties & which are active to be queried on reports
 */
export default class ProjectPropertiesStore {
  public data: ProjectProperty[] = [];
  public lastChanged: number | null = null;
  public dataObject: {} = {};

  public add(items: ProjectProperty[]) {
    if (!Array.isArray(items)) {
      console.error('this should be an array:', items);
      return;
    }

    this.data.push(...items);
    this.setLastChanged();

    for (let i = 0; i < items.length; i++) {
      this.dataObject[items[i].id_project_property] = {
        inactive: items[i].inactive,
      };
    }
  }

  public getDistinctStatesCities() {
    const uniqueCitiesAndStatesSet = new Set(
      this.data.map((item) => {
        return JSON.stringify({ city: item.city.replaceAll("'", "''"), state: item.state });
      })
    );

    return Array.from(uniqueCitiesAndStatesSet).map((item) => JSON.parse(item) as { city: string; state: string });
  }

  public getDistinctProjectIds() {
    return this.data
      .filter((i) => !i.inactive)
      .map((i) => i.id_project_property)
      .filter(filterDistinct);
  }

  public getDistinctSourceIds() {
    return this.data
      .filter((i) => !i.inactive)
      .map((i) => i.id_source)
      .filter(filterDistinct);
  }

  public getLocationCenter() {
    if (!this.data.length) {
      return null;
    }

    let sumLatitude = 0;
    let sumLongitude = 0;

    this.data.forEach((item) => {
      sumLatitude += item.latitude;
      sumLongitude += item.longitude;
    });

    return {
      latitude: sumLatitude / this.data.length,
      longitude: sumLongitude / this.data.length,
    };
  }

  public isActive(idProjectProperty: number) {
    // const item = this.data.find((i) => i.id_project_property === idProjectProperty);
    // return item ? !item.inactive : undefined;
    return this.dataObject[idProjectProperty] ? !this.dataObject[idProjectProperty].inactive : undefined;
  }

  public removeAll() {
    this.data.splice(0, this.data.length);
    this.setLastChanged();
  }

  public removeByShapeId(shapeId: string) {
    for (let i = this.data.length - 1; i >= 0; i--) {
      if (this.data[i].shapeId === shapeId) {
        this.data.splice(i, 1);
      }
    }
    this.setLastChanged();
  }

  // Used on reactive components that watch this prop to detect data changes
  public setLastChanged() {
    this.lastChanged = Date.now();
  }

  public toggleInactive(item: ProjectProperty) {
    const found = this.data.filter((i) => i.id_project_property === item.id_project_property);
    found.forEach((i) => (i.inactive = !i.inactive));
    this.dataObject[item.id_project_property].inactive = !this.dataObject[item.id_project_property].inactive;
    this.setLastChanged();
  }
}
