//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import './css/internalItem.scss';

export default Vue.extend({
  name: 'InternalSubBody',

  props: ['item'],

  methods: {
    selectItem() {
      if (this.item.enabled) {
        const navItem = document.getElementById(`nav-second-int-${this.item.id}`);
        navItem.click();
        this.$store.commit('Menu/setActive', this.item);
      }
    },
  },
});
