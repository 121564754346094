export const CLEAR_STATE = {
  id_project_phase: [],
  zip_code: '',
  state: [],
  city: [],
  neighborhood: [],
  id_builder: [],
  id_developer: [],
  id_real_estate: [],
  draft: [],
  id_user_created: [],
  id_source: '',
  project_property: '',
  autoCompleteOriginalSearch: '',
  filterValues: new Object(),
  isFilterApplied: false,
};

export const state = () => ({
  showFiltersFlag: false,
  fieldValue: '',
  autocompleteTypeSelected: '',
  showAutocompleteContainer: false,
  filterValues: {
    id_project_phase: [],
    zip_code: '',
    state: [],
    city: [],
    neighborhood: [],
    id_builder: [],
    id_developer: [],
    id_real_estate: [],
    draft: [],
    id_user_created: [],
    id_source: '',
    project_property: '',
  },
  isFilterApplied: false,
});
