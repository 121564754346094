export default {
  setActive(state, sessionObj) {
    state.isActive = true;
    state.expired = false;
    state.userLogoff = false;
    state.profileError = false;
    state.accessToken = sessionObj.accessToken;
    state.userName = sessionObj.name;
    state.eMail = sessionObj.eMail;
    state.userID = sessionObj.id;
    state.userUUID = sessionObj.uuid;
    state.userEmail = sessionObj.email;
  },

  setOldGeo(state, status) {
    state.oldGeo = status;
  },

  setFirstAccess(state, status) {
    state.firstAccess = status;
  },

  setDeactive(state) {
    state.isActive = false;
    state.expired = true;
    state.accessToken = '';
    state.userName = '';
    state.eMail = '';
    state.userID = '';
    state.userUUID = '';
    state.userEmail = '';
    state.profiles = [0];
    state.navigation = '';
  },

  setNewSession(state) {
    state.expired = false;
    state.isActive = false;
    state.userLogoff = false;
    state.oldGeo = false;
    state.profileError = false;
    state.firstAccess = false;
    state.accessToken = '';
    state.userName = '';
    state.eMail = '';
    state.userID = '';
    state.userUUID = '';
    state.userEmail = '';
    state.changePass = false;
    state.changePassUser = '';
    state.changePassToken = '';
  },

  setLogoff(state) {
    state.userLogoff = true;
  },

  setChangePass(state, status) {
    state.changePass = status;
  },

  setChangePassUser(state, status) {
    state.changePassUser = status;
  },

  setChangePassToken(state, status) {
    state.changePassToken = status;
  },

  setUserProfile(state, status) {
    state.profiles = [];
    state.profiles.push(...status);
  },

  setProfExpired(state, status) {
    state.profExpired = [];
    state.profExpired.push(...status);
  },

  setProfileError(state, status) {
    state.profileError = status;
  },

  setAnonymousId(state, status) {
    state.anonymousId = status;
  },

  setRoles(state, roles) {
    state.roles = roles;
  },

  setNavigation(state, navigation) {
    state.navigation = navigation;
  },
};
