export default {
  toggle(context) {
    context.commit('toggle');
  },

  selectOption(context, { type, option }) {
    context.commit('selectOption', { type, option });
  },

  clearOption(context, { type, option }) {
    context.commit('clearOption', { type, option });
  },

  setFieldValue(context, { value }) {
    context.commit('setFieldValue', { value });
  },

  setFieldId(context, value) {
    context.commit('setFieldId', value);
  },

  selectRangeValues(context, { type, minValue, maxValue }) {
    context.commit('selectRangeValues', { type, minValue, maxValue });
  },

  selectValue(context, { type, value }) {
    context.commit('selectValue', { type, value });
  },

  setAutocompleteTypeSelected(context, type) {
    context.commit('setAutocompleteTypeSelected', type);
  },

  clearFilters(context) {
    context.commit('clearFilters');
  },

  toggleShowMoreFilters(context) {
    context.commit('toggleShowMoreFilters');
  },

  toggleShowAutocomplete(context) {
    context.commit('toggleShowAutocomplete');
  },
};
