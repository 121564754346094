import { CensoData, Circle, Polygon } from '../../assets/js/Censo';

export default {
  clear(state: CensoData) {
    state.circles = [];
    state.polygons = [];
  },

  addCircle(state: CensoData, circle: Circle) {
    state.circles.push(circle);
  },

  addPolygon(state: CensoData, polygon: Polygon) {
    if (
      !Array.isArray(polygon.data) ||
      !Array.isArray(polygon.data[0]) ||
      typeof polygon.data[0][0] !== 'number' ||
      typeof polygon.data[0][1] !== 'number'
    ) {
      console.error('Invalid data to set polygon on Censo query:', polygon);
      return;
    }

    state.polygons.push(polygon);
  },

  removeShape(state: CensoData, shapeId: string) {
    state.circles = state.circles.filter((i) => i.parentId !== shapeId);
    state.polygons = state.polygons.filter((i) => i.shapeId !== shapeId);
  },
};
