import { PerformanceChartsApiBody } from '../../components/Charts/common';

export default {
  setDates(state: PerformanceChartsApiBody, dates: PerformanceChartsApiBody['dates']) {
    state.dates = dates;
  },

  setSeries(state: PerformanceChartsApiBody, series: PerformanceChartsApiBody['series']) {
    state.series = series;
  },
};
