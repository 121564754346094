//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './loginStyleDesk.css';
import './loginStyleMobile.css';

import Utils from '~/assets/js/utils';

import { chemistryDialogV2 } from 'chemistry-vue';

export default {
  name: 'LoginIndex',

  components: {
    'c-dialog': chemistryDialogV2,
  },

  data() {
    return {
      utils: new Utils(),
      isMobile: false,
    };
  },

  // the tag <nuxt /> this a bit hard to understand, it receive the content of nuxt-router, in this case, at first the loginContent (fields to login).
  mounted() {
    this.isMobile = this.utils.mobileCheck();
    this.$router.push({
      path: `/Login/loginContent`,
      name: 'Login-loginContent',
      query: this.$route.query,
    });
  },

  methods: {
    backToLogin() {
      this.$store.commit('Session/setNewSession');
      this.$store.commit('Session/setProfileError', false);
    },
  },
};
