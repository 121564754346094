// @ts-nocheck

export default class Utils {
  formatMoeda(val) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
  }

  formatNumber(val, digits = 2) {
    var number = Number(val).toLocaleString('pt-BR', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
    return number;
  }

  formatCEP(val) {
    var cep = val;
    if (cep.length == 8) {
      cep = cep.substring(0, 5) + '-' + cep.substring(5, 8);
    }
    return cep;
  }

  convertStringNumber(val) {
    var number = val;

    while (number != number.replace('.', '')) {
      number = this.convertStringNumber(number.replace('.', ''));
      return number;
    }

    number = number.replace(',', '.');

    if (isNaN(number)) {
      number = 0;
    }

    return parseFloat(number);
  }

  capitalizeFirstLetter(val) {
    val = val
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    return val;
  }

  capitalizeAllFirstLetter(val) {
    var sentence = val.toLowerCase().split(' ');

    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i].charAt(0).toUpperCase() + sentence[i].substring(1);
    }

    return sentence.join(' ');
  }

  /**
   * Capitalize first letters such as "JD.BLABLA" to "Jd.Blabla"; and "JD BLOBLO" to "Jd Bloblo"
   */
  capitalizeFirstLetterWithSpaceAndDot(str, regex = /\s|\./) {
    let newString = '';

    for (let i = 0; i < str.length; i++) {
      if (!i || regex.test(str[i - 1])) {
        newString += str[i].toUpperCase();
      } else {
        newString += str[i].toLowerCase();
      }
    }

    return newString;
  }

  formatData(val) {
    var dtCompleta = new Date(val);

    let dia = dtCompleta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2, useGrouping: false });
    let mes = (dtCompleta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2, useGrouping: false });
    let ano = dtCompleta.getFullYear();

    return `${dia}-${mes}-${ano}`;
  }

  formatHora(val) {
    var dtCompleta = new Date(val);

    let hora = dtCompleta.getHours();
    hora = hora.toString().padStart(2, '0');

    let minuto = dtCompleta.getMinutes();
    minuto = minuto.toString().padStart(2, '0');

    return hora + ':' + minuto;
  }

  formatPhone(val) {
    const onlyNumbers = val.replace(/\D/g, '');

    if (onlyNumbers.length === 0) {
      return '';
    }

    const pattern =
      onlyNumbers.length < 11
        ? onlyNumbers.match(/(\d{0,2})(\d{0,4})(\d{0,4})/)
        : onlyNumbers.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);

    return '(' + pattern[1] + (pattern[1].length < 2 ? '' : ') ' + pattern[2] + (!pattern[3] ? '' : '-' + pattern[3]));
  }

  convertDistance(payload) {
    var distanceInKms = 0;
    switch (payload.actualDistanceUnit) {
      case 'miles':
        distanceInKms = payload.distanceValue / 0.62137;
        break;
      case 'kms':
        distanceInKms = payload.distanceValue;
        break;
      case 'metres':
        distanceInKms = payload.distanceValue / 1000;
        break;
      default:
        distanceInKms = undefined;
    }

    switch (payload.expectedDistanceUnit) {
      case 'miles':
        return distanceInKms * 0.62137;
      case 'kms':
        return distanceInKms;
      case 'metres':
        return distanceInKms * 1000;
      default:
        return undefined;
    }
  }

  toRadians(value) {
    return (value * Math.PI) / 180;
  }

  toDegrees(value) {
    return (value * 180) / Math.PI;
  }

  mobileCheck() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || window.opera);
    return check;
  }

  stringifyAndParseObject(value) {
    if (typeof value === 'undefined' || value === '{}') {
      return [];
    }
    let result = JSON.stringify(value);
    result = result.replace(/\\/g, '');
    result = result.replaceAll(`","`, ',');
    result = result.replace(`"{"`, '[');
    result = result.substring(0, result.length - 3) + ']';
    return JSON.parse(result);
  }

  removeAccents(str: String) {
    return !str ? '' : str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  // Sort array by key or by value (when key is null)
  sortByKey<T>(data: T[], key: keyof T | null, caseSensitive = false): T[] {
    return data.sort((a, b) => {
      let itemA = key ? (a[key] as string) : a;
      let itemB = key ? (b[key] as string) : b;

      if (!itemA) {
        return 1;
      }
      if (!itemB) {
        return -1;
      }

      itemA = this.removeAccents(itemA).trim();
      itemB = this.removeAccents(itemB).trim();

      if (!caseSensitive) {
        return itemA.toLowerCase() > itemB.toLowerCase() ? 1 : -1;
      }
      return itemA > itemB ? 1 : -1;
    });
  }

  sortArray(data: string[]) {
    return data.sort((a, b) => {
      const itemA = this.removeAccents(a).trim();
      const itemB = this.removeAccents(b).trim();

      return itemA.toLowerCase() > itemB.toLowerCase() ? 1 : -1;
    });
  }

  getUfAbbreviation(uf: string): string {
    const normalized = this.removeAccents(uf.trim()).toLowerCase();

    const ufs = [
      { label: 'Acre', abbrev: 'AC' },
      { label: 'Alagoas', abbrev: 'AL' },
      { label: 'Amapá', abbrev: 'AP' },
      { label: 'Amazonas', abbrev: 'AM' },
      { label: 'Bahia', abbrev: 'BA' },
      { label: 'Ceará', abbrev: 'CE' },
      { label: 'Distrito Federal', abbrev: 'DF' },
      { label: 'Espírito Santo', abbrev: 'ES' },
      { label: 'Goiás', abbrev: 'GO' },
      { label: 'Maranhão', abbrev: 'MA' },
      { label: 'Mato Grosso', abbrev: 'MT' },
      { label: 'Mato Grosso do Sul', abbrev: 'MS' },
      { label: 'Minas Gerais', abbrev: 'MG' },
      { label: 'Pará', abbrev: 'PA' },
      { label: 'Paraíba', abbrev: 'PB' },
      { label: 'Paraná', abbrev: 'PR' },
      { label: 'Pernambuco', abbrev: 'PE' },
      { label: 'Piauí', abbrev: 'PI' },
      { label: 'Rio de Janeiro', abbrev: 'RJ' },
      { label: 'Rio Grande do Norte', abbrev: 'RN' },
      { label: 'Rio Grande do Sul', abbrev: 'RS' },
      { label: 'Rondônia', abbrev: 'RO' },
      { label: 'Roraima', abbrev: 'RR' },
      { label: 'Santa Catarina', abbrev: 'SC' },
      { label: 'São Paulo', abbrev: 'SP' },
      { label: 'Sergipe', abbrev: 'SE' },
      { label: 'Tocantins', abbrev: 'TO' },
    ];

    const item = ufs.find((item) => this.removeAccents(item.label).toLowerCase() === normalized);
    return item?.abbrev || uf;
  }

  validateEmail = (email: string) => {
    return email.match(
      // eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  /**
   * Normalize "São José" to "sao-jose"
   */
  normalizeString = (str) => {
    return str
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .replace(`'`, '')
      .toLowerCase();
  };

  getMarkersIcons = () => ({
    default: {
      url: require('~/assets/img/Components/mapPoints/default.png'),
    },
    pin: {
      url: require('~/assets/img/Components/mapPoints/pin.png'),
    },
    defaultHover: {
      url: require('~/assets/img/Components/mapPoints/defaultHover.png'),
      scaledSize: new window.google.maps.Size(24, 24),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(12, 20),
    },
    inactive: {
      url: require('~/assets/img/Components/mapPoints/inactive.png'),
    },
    inactiveHover: {
      url: require('~/assets/img/Components/mapPoints/inactiveHover.png'),
      scaledSize: new window.google.maps.Size(24, 24),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(12, 20),
    },
  });

  getArrayUniqueValues = <T>(array: T[]) => {
    const unique = (value: any, index: number, array: any[]) => {
      return array.indexOf(value) === index;
    };

    return array.filter(unique);
  };

  removeFromArray = (array: any[], content: any) => {
    const index = array.indexOf(content);

    if (index !== -1) {
      array.splice(index, 1);
    }
  };

  machineName(id, email) {
    var code = id;
    var user = email;

    user = user.substring(0, user.indexOf('@'));
    if (user.indexOf('.') > 0) user = user.substring(0, user.indexOf('.'));

    code = ('00000' + code).substring(('00000' + code).length - 5);

    return code + '-' + user;
  }

  /**
   * Allow dragging element on screen
   * Element must have position: absolute
   */
  dragElement = (containerId: string, draggableId: string) => {
    const container = document.getElementById(containerId);
    const draggable = document.getElementById(draggableId);

    if (!container || !draggable) {
      return;
    }

    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    if (draggable) {
      draggable.onmousedown = dragMouseDown;
    } else {
      container.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e: any) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e: any) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      container!.style.top = container!.offsetTop - pos2 + 'px';
      container!.style.left = container!.offsetLeft - pos1 + 'px';
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  };

  readFileAsync(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        resolve(event.target.result);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(blob);
    });
  }

  async readBlob(blob) {
    try {
      return await this.readFileAsync(blob);
    } catch (error) {
      console.error('Error reading blob:', error);
    }
  }

  /**
   * Formats a given ISO string to a database-friendly format.
   * If no ISO string is provided, the current date and time will be used.
   * Returns the formatted date and time string in the format 'YYYY-MM-DD HH:mm:ss'.
   */
  formatDateToDatabase(isoString?: string = undefined) {
    let date = new Date();

    if (isoString) {
      date = new Date(isoString);
    }

    const offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - offset);
    isoString = date.toISOString();
    return isoString.substring(0, 10) + ' ' + isoString.substring(11, 19);
  }

  slugify(input: string): string {
    if (!input) {
      return '';
    }

    let slug = input.toLowerCase().trim();

    // remove accents from charaters
    slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // replace invalid chars with spaces
    slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

    // replace multiple spaces or hyphens with a single hyphen
    slug = slug.replace(/[\s-]+/g, '-');

    return slug;
  }
}
