export default {
  setFilter(state, filter) {
    state.filter = filter;
  },

  setPreviousPageName(state, previousPageName) {
    state.previousPageName = previousPageName;
  },

  setSearchType(state, searchType) {
    state.searchType = searchType;
  },
};
