//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import NavHR from './NavHR.vue';
import './css/headerDesk.scss';
import './css/headerMobile.scss';

export default Vue.extend({
  name: 'Header',

  components: {
    'nav-hr': NavHR,
  },

  data() {
    return {
      activeLabel: '',
    };
  },

  methods: {
    toggleOpen() {
      this.$emit('toggleOpen');
    },

    setActiveLabel() {
      this.$store.state.Menu.menuItems.forEach((item) => {
        if (item.active) {
          this.activeLabel = item.label;
        }

        if (item.subMenus) {
          item.subMenus.forEach((subItem) => {
            if (subItem.active) {
              this.activeLabel = subItem.label;
            }

            if (subItem.subMenus) {
              subItem.subMenus.forEach((tirthItem) => {
                if (tirthItem.active) {
                  this.activeLabel = tirthItem.label;
                }
              });
            }
          });
        }
      });
    },
  },

  mounted() {
    this.setActiveLabel();
  },

  watch: {
    '$store.state.Menu.menuItems': {
      handler: function () {
        this.setActiveLabel();
      },
      deep: true,
    },
  },
});
