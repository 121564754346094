export default {
  setOpen(context, payload) {
    context.commit('setOpen', payload);
  },

  setIdNumber(context, payload) {
    context.commit('setIdNumber', payload);
  },

  setFichaTecnica(context, payload) {
    context.commit('setFichaTecnica', payload);
  },

  setTabelaVendas(context, payload) {
    context.commit('setTabelaVendas', payload);
  },

  setMonitoramento(context, payload) {
    context.commit('setMonitoramento', payload);
  },

  setTableChart(context, payload) {
    context.commit('setTableChart', payload);
  },
};
