import { PerformanceChartsApiBody } from '../../components/Charts/common';

export const state = (): PerformanceChartsApiBody => {
  return {
    dates: [],
    series: {
      areaLaunch: [],
      areaSold: [],
      unitsConsolidated: [],
      unitsLaunch: [],
      unitsSold: [],
      unitsStock: [],
      vgvConsolidated: [],
      vgvLaunch: [],
      vgvSold: [],
      vgvStock: [],
      vso: [],
    },
  };
};
