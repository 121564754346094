//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './loginStyleDesk.css';
import './loginStyleMobile.css';

import Comm from '~/assets/js/Communication';
import Utils from '~/assets/js/utils';

import { chemistryButton, chemistryDialogV2, chemistryText } from 'chemistry-vue';

export default {
  name: 'PasswordRegistration',

  props: ['intent'],

  components: {
    'c-text': chemistryText,
    'c-button': chemistryButton,
    'c-dialog': chemistryDialogV2,
  },

  data() {
    return {
      txtTitle: '',
      txtPass: '',
      txtConfirm: '',
      goodToGo: false,
      passError: false,
      passConfError: false,
      errorMessage: '',
      errorConfMessage: '',
      passGoodToGo: false,
      goodPass: false,
      imgNeutral: require('~/assets/img/Components/login/neutral.png'),
      imgOk: require('~/assets/img/Components/login/ok.png'),
      imgNoOk: require('~/assets/img/Components/login/noOk.png'),
      eightStatus: null,
      upperStatus: null,
      specialStatus: null,
      user: null,
      token: null,
      showModal: false,
      isModalError: false,
      txtModal: '',
      utils: new Utils(),
      isMobile: false,
      today: new Date(),
    };
  },

  mounted() {
    this.isMobile = this.utils.mobileCheck();

    this.eightStatus = this.imgNeutral;
    this.upperStatus = this.imgNeutral;
    this.specialStatus = this.imgNeutral;

    if (this.$store.state.Session.firstAccess) {
      this.txtTitle = 'Cadastro de senha';
    } else {
      this.txtTitle = 'Redefinir senha';
    }

    this.user = this.$store.state.Session.changePassUser;
    this.token = this.$store.state.Session.changePassToken;
  },

  methods: {
    setPassword(val) {
      this.txtPass = val;

      if (!this.checkUpCase(this.txtPass) || !this.checkSpecial(this.txtPass) || !this.checkNumber(this.txtPass)) {
        this.errorMessage = 'A senha escolhida não atende aos requisitos de segurança.';
        this.passError = true;
      } else {
        this.errorMessage = '';
        this.passError = false;
        this.passGoodToGo = true;
      }

      if (this.txtPass == '') {
        this.upperStatus = this.imgNeutral;
        this.specialStatus = this.imgNeutral;
        this.eightStatus = this.imgNeutral;
        this.errorMessage = '';
        this.passError = false;
        this.passGoodToGo = false;
      }

      if (this.txtConfirm != '') {
        this.setConfirm(this.txtConfirm);
      }
    },

    liveCheck(val) {
      this.txtPass = val;

      if (this.checkUpCase(this.txtPass)) {
        this.upperStatus = this.imgOk;
      } else {
        this.upperStatus = this.imgNoOk;
      }

      if (this.checkSpecial(this.txtPass)) {
        this.specialStatus = this.imgOk;
      } else {
        this.specialStatus = this.imgNoOk;
      }

      if (this.checkNumber(this.txtPass)) {
        this.eightStatus = this.imgOk;
      } else {
        this.eightStatus = this.imgNoOk;
      }
    },

    setConfirm(val) {
      this.txtConfirm = val;

      if (this.txtConfirm == this.txtPass) {
        this.passConfError = false;
        this.errorConfMessage = '';
        this.goodToGo = true;
        this.goodPass = true;
      } else {
        this.passConfError = true;
        this.errorConfMessage = 'A senha aqui não corresponde com a do campo acima';
        this.goodToGo = false;
        this.goodPass = false;
      }

      if (this.txtConfirm == '') {
        this.passConfError = false;
        this.errorConfMessage = '';
        this.goodToGo = false;
        this.goodPass = false;
      }
    },

    generatePass() {
      let comm = new Comm();

      let passObj = {
        email: this.user,
        'pin-code': this.token,
        'new-password': this.txtPass,
      };

      comm.changePassword(passObj).then((res) => {
        if (res && res.status == 200) {
          this.isModalError = false;
          this.txtModal = 'Sua senha foi atualizada com sucesso, você será redirecionado para a plataforma.';
          this.$store.commit('Session/setOldGeo', true);
          this.showModal = true;
        } else {
          res.json().then((error) => {
            this.isModalError = true;
            this.txtModal = `Houve um problema com a troca de senha: ${error.errorMessage}`;
            this.showModal = true;
          });
        }
      });
    },

    modalAction() {
      let comm = new Comm();

      if (this.isModalError) {
        this.$store.commit('Session/setChangePassUser', '');
        this.$store.commit('Session/setChangePassToken', '');
        this.$store.commit('Session/setChangePass', false);
        this.$router.push({ path: '/' });
      } else {
        let userObj = {
          email: this.user,
          password: this.txtPass,
        };
        let tokenObj = {
          token: '',
        };
        comm.datazapGatewayLogin(userObj).then((res) => {
          if (res && res.status == 200) {
            res.text().then((data) => {
              tokenObj.token = data;
              localStorage.setItem('token', tokenObj.token);
              this.validateTokenAndEnter(tokenObj);
            });
          }
        });
      }
      this.showModal = false;
    },

    validateTokenAndEnter(tokenObj) {
      let comm = new Comm();

      comm.getBackendData('validToken', 'POST', tokenObj, null, false).then((res) => {
        if (res && res.status == 200) {
          res.json().then((data) => {
            let sessionObj = {
              accessToken: tokenObj.token,
              name: data.fullname,
              eMail: data.email,
              id: data.id,
              email: data.username,
              roles: data.roles,
              uuid: data.uuid,
            };
            this.getProfiles(sessionObj);
            this.$store.commit('Session/setRoles', data.roles);
          });
        }
      });
    },

    getProfiles(sessionObj) {
      let comm = new Comm(sessionObj.accessToken);

      comm.getBackendData('checkUserAccess', 'GET', null, sessionObj.id).then((res) => {
        if (res && res.status == 200) {
          res.json().then((data) => {
            // delete change pass data:
            this.$store.commit('Session/setChangePassToken', '');
            this.$store.commit('Session/setChangePass', '');
            this.$store.commit('Session/setChangePass', false);
            //activate user session
            this.$store.commit('Session/setActive', sessionObj);

            let profileArray = [];
            let expiredArray = [];
            let isOlx = false;
            let primeYield = false;

            data.records.forEach((item) => {
              let strEnd = '99999999';

              if (item.id_status == 1) {
                profileArray.push(item.id_product);
              } else if (item.id_status != 1 && item.date_end_period) {
                expiredArray.push(item.id_product);
              }

              if (item.adm_code == 32 && sessionObj.roles.indexOf('ADMIN') != -1) {
                isOlx = true;
              }

              if (item.adm_code == 100023) {
                primeYield = true;
              }
            });

            isOlx ? profileArray.push(99) : null;
            primeYield ? profileArray.push(98) : null;

            if (profileArray.length > 0 || expiredArray.length > 0) {
              this.$store.commit('Session/setUserProfile', profileArray);
              this.$store.commit('Session/setProfExpired', expiredArray);
              this.sendUserToSomewhere(profileArray);
            } else {
              this.isModalError = true;
              this.txtModal = 'Ocorreu um problema na obtenção dos perfis do usuário ou ele não possui perfis.';
              this.showModal = true;
            }
          });
        } else {
          this.isModalError = true;
          this.txtModal = 'Ocorreu um problema na obtenção dos perfis do usuário ou ele não possui perfis.';
          this.showModal = true;
        }
      });
    },

    checkUpCase(str) {
      return /[A-Z]/.test(str);
    },

    checkSpecial(str) {
      return /\W/.test(str);
    },

    checkNumber(str) {
      //return /\d/.test(str);
      return str.length >= 8;
    },

    sendUserToSomewhere(profiles) {
      // this is the profiles we have for now
      // 0 = overview (only in front-end);
      // 1 = geoimovel (database);
      // 2 = quantoVale (database);
      // 3 = ondeVale (database);
      // 4 = laudo (database);
      // 5 = lupa (database);
      let forceUserTo = '/geoimovel';

      if (this.$store.state.Session.profiles.indexOf(1) > -1 || this.$store.state.Session.profiles.indexOf(11) > -1) {
        forceUserTo = '/geoimovel';
      } else if (this.$store.state.Session.profiles.indexOf(5) > -1) {
        forceUserTo = '/Estudos/lupa';
      } else {
        forceUserTo = '/suporte';
      }

      this.$router.push({ path: forceUserTo });
    },
  },
};
