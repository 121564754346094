export default {
  setClearForm(state) {
    let date = new Date();
    let ano = date.getFullYear();
    state.id = null;
    state.id_source = null;
    state.id_project_phase = 100;
    state.date_created = null;
    state.date_updated = null;
    state.id_user_created = '';
    state.id_user_updated = '';
    state.name = '';
    state.edifice_name = '';
    state.enterpriseFaseado = '02';
    state.phase_comment = '';
    state.id_project_type = 100;
    state.launch_year = ano;
    state.launch_month = 100;
    state.date_delivery = '';
    state.date_launch_expected = '';
    state.num_blocks = '';
    state.id_research = 100;
    state.research = null;
    state.txtCep = '';
    state.txtNroEmpreendimento = null;
    state.txtLogradouro = '';
    state.txtBairro = '';
    state.txtCidade = '';
    state.txtEstado = '';
    state.txtDistrito = '';
    state.txtZonaValor = '';
    state.website = '';
    state.comment_building = '';
    state.latitude = '';
    state.longitude = '';
    state.location = [];
    state.buildersObj = {
      canAdd: true,
      canThrash: false,
    };
    state.builderLines = [
      {
        id: 1,
        data: null,
      },
    ];
    state.developersObj = {
      canAdd: true,
      canThrash: false,
    };
    state.developerLines = [
      {
        id: 1,
        data: null,
      },
    ];
    state.realEstateObj = {
      canAdd: true,
      canThrash: false,
    };
    state.realEstateLines = [
      {
        id: 1,
        data: null,
      },
    ];
    state.builderData = [];
    state.developerData = [];
    state.realEstateData = [];
    state.infraArray = [];
    state.ind_cub = '';
    state.ind_tax = '';
    state.ind_cub_h82n = '';
    state.ind_incc = '';
    state.ind_cub_igpm = '';
    state.indSelGrupo = 100;
    state.id_launch_pattern = 100;
    state.indData = '';
    state.land_area = '';
    state.area_total = '';
    state.ind_vgv_brl = '';
    state.property = [
      {
        name: '',
        id_building_type: 100,
        quantity_type: null,
        bedroom: null,
        bathroom: null,
        washroom: null,
        suite: null,
        garage: null,
        garage_covered: null,
        garage_uncovered: null,
        area_util: null,
        area_total: null,
        comment_values: '',
        txtValUnidade: '',
        unitValTabUtil: '',
        unitValTabTotal: '',
        unity_incc_di: '',
        area_util_incc_di: '',
        unitValTabTotalIncc: '',
        unitValTabUnidadeCub: '',
        area_util_cub: '',
        unitValTabTotalCub: '',
        unitValTabUnidadeIgpm: '',
        area_util_igpm: '',
        unitValTabTotalIgpm: '',
        ind_vgv_brl: '0,00',
      },
    ];
    state.propertyControls = [
      {
        save: false,
        edit: false,
      },
    ];
    state.unitValCanAdd = false;
    state.unitValCanDel = false;
    state.num_units = null;
    state.pavement_quantity = null;
    state.pavement_unity = null;
    state.num_elevator = null;
    state.variable_grid = 0;
    state.variable_grid_desc = '';
    state.viewable = false;
    state.project_address = null;
    state.zone = null;
    state.photo = null;
    state.project_feature = [];
    state.id_financing = '9999';
    state.draft = true;
    state.project_bank = [];
    state.id_value_zone = 0;
  },

  setValue(state, { field, value }) {
    if (value === undefined) {
      console.warn(`Enterprise.mutations.setValue: value is undefined for field ${field}`);
    }

    state[field] = value;
  },

  setArrayValue(state, { field, index, value }) {
    state.property[index][field] = value;
  },

  setIcon(state, { index, icon, value }) {
    if (state.propertyControls[index]) {
      state.propertyControls[index][icon] = value;
    }
  },

  setAddIcon(state, value) {
    state.unitValCanAdd = value;
  },

  setDelIcon(state, value) {
    state.unitValCanDel = value;
  },

  setBuildersObj(state, value) {
    state.buildersObj = value;
  },

  setDevelopersObj(state, value) {
    state.developersObj = value;
  },

  setRealEstateObj(state, value) {
    state.realEstateObj = value;
  },

  setBuilderLines(state, value) {
    state.builderLines = [];
    state.builderLines = value.slice();
  },

  setDeveloperLines(state, value) {
    state.developerLines = [];
    state.developerLines = value.slice();
  },

  setRealEstateLines(state, value) {
    state.realEstateLines = [];
    state.realEstateLines = value.slice();
  },

  setBuilderData(state, value) {
    state.builderData = [];
    state.builderData = value.slice();
  },

  setDeveloperData(state, value) {
    state.developerData = [];
    state.developerData = value.slice();
  },

  setRealEstateData(state, value) {
    state.realEstateData = [];
    state.realEstateData = value.slice();
  },

  setInfraArray(state, value) {
    state.infraArray = [];
    state.infraArray = value.slice();
  },

  setLocation(state, { lat, lon }) {
    state.location = [lat, lon];
    state.latitude = lat;
    state.longitude = lon;
  },

  setAreasTotalTerreno(state, value) {
    state.areasTotalTerreno = value;
  },

  addControlItem(state) {
    state.propertyControls.push({
      save: false,
      edit: false,
    });
  },

  addUnitValItem(state, obj) {
    state.property.push(obj);
  },

  removeUnitValItem(state, index) {
    state.property.splice(index, 1);
  },

  addProjectBank(state, id) {
    const exists = state.project_bank.find((item) => item.id_bank == id);

    if (!exists) {
      state.project_bank.push({
        id_bank: id,
      });
    }
  },

  removeProjectBank(state, id) {
    state.project_bank = state.project_bank.filter((item) => item.id_bank != id);
  },
};
