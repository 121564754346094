export const state = () => ({
  apiKey: 'AIzaSyBMgtgarMevX_3j5vFuSVQAg0bTTG4EXOY',
  mapConfig: {},
  geometryOptions: {
    fillColor: 'rgba(110, 10, 214, 0.3)',
    strokeColor: 'rgba(97, 6, 210, 1)',
    zIndex: 1,
    clickable: false,
    editable: false,
    dragabble: false,
  },
  isStreetView: false,
  streetViewObj: {},
  listenerFiltersApplied: false,
  map: null,
  autocompletePredictions: [],
  projectPropertyAutocompletePredictions: [],
  mapPoints: [],
  mapTypeId: 'roadmap',
});
